import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyC1xWf-W-hzuaKPEiz9rG4v1u59SaeQ2Nk",
    authDomain: "realestate-36757.firebaseapp.com",
    projectId: "realestate-36757",
    storageBucket: "realestate-36757.appspot.com",
    messagingSenderId: "142116183032",
    appId: "1:142116183032:web:574ea2c72e309991f1540d",
    measurementId: "G-VD774DCJEH"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }

//   firebase.initializeApp(config);

  export default firebase;