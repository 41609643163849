import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moveToAddPropertyPage: false
    }
  }

  logOut = () => {
    localStorage.clear()
  }

  navigateToAddProperty = () => {
    let loginName = localStorage.getItem("loginName")
    let comp = this
    if(loginName == null) {
      alert("Please login to Post Property!!")
    } else {
      comp.setState({
        moveToAddPropertyPage: true
      })
    }
  }

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'

        let loginName = localStorage.getItem("loginName")

        return (

            <div>
              {this.state.moveToAddPropertyPage? <Redirect to = "/add-property" /> : null}
                <div className="navbar-area">
                <nav className="navbar navbar-area navbar-expand-lg">
                  <div className="container nav-container">
                    <div className="responsive-mobile-menu">
                      <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="icon-left" />
                        <span className="icon-right" />
                      </button>
                    </div>
                    <div className="logo readeal-top">
                      <Link to="/"><img src={"/logo2.jpeg"} style = {{height:"60px", width: "120px;"}} alt="logo" /></Link>
                    </div>
                    <div className="nav-right-part nav-right-part-mobile">
                    {loginName == null ?
                      <Link className="btn btn-yellow" to="/registration" onClick = {this.navigateToAddProperty}>Post property Free </Link> :
                      <Link className="btn btn-yellow" to="/add-property" onClick = {this.navigateToAddProperty}>Post property Free</Link> }
                    </div>
                    <div className="collapse navbar-collapse" id="realdeal_main_menu">
                      <ul className="navbar-nav menu-open readeal-top">
                        {/* <li className="menu-item-has-children current-menu-item">
                          <a href="#">Home</a>
                          <ul className="sub-menu">
                            <li><Link to="/">Home 01</Link></li>
                            <li><Link to="/home-v2">Home 02</Link></li>
                            <li><Link to="/home-v3">Home 03</Link></li>
                            <li><Link to="/home-v4">Home 04</Link></li>
                          </ul>
                        </li> */}
                        <li><Link to="/">Home</Link></li>
                        <li className="menu-item-has-children">
                          <a href="#">Property</a>
                          <ul className="sub-menu">
                            <li><Link to="/property">Available Properties</Link></li>
                            <li><Link to="/properties-by-city">Property By City</Link></li>
                            <li><Link to="/recent-properties">Recent Properties</Link></li>
                            <li><Link to="/agents-by-city">Agents By Top Cities</Link></li>
                            <li><Link to="/builders-by-city">Builder By Top Cities</Link></li>
                            <li><Link to="/post-your-requirement">Post Your Buy Requirement</Link></li>
                          </ul>
                        </li>
                        <li><Link to="/agents">Agent / Builder Login</Link></li>
                        {loginName == null ?
                        <li><Link to = "/registration" onClick = {this.navigateToAddProperty} >Post property Free</Link></li> :
                        <li><Link to = "/add-property" onClick = {this.navigateToAddProperty} >Post property Free</Link></li> }

                        <li><Link to="/pricing">Membership</Link></li>

                        {loginName !== null && loginName !== "" ?
                        <li className="menu-item-has-children">
                          <a href="#">{loginName}</a>
                          <ul className="sub-menu">
                            <li><Link to="/profile/user">My Profile</Link></li>
                            <li><Link to="/profile/ads">My Ads</Link></li>
                            <li><Link to="/profile/inbox">Inbox</Link></li>
                            <li><Link to="/profile/membership">Membership</Link></li>
                            <li><Link to="/" onClick = {this.logOut}>Log Out</Link></li>
                          </ul>
                        </li> : null }

                        {/* <li className="menu-item-has-children">
                          <a href="#">Pages</a>
                          <ul className="sub-menu">
                            <li><Link to="/About">About</Link></li>
                            <li><Link to="/advisor">Advisor</Link></li>
                            <li><Link to="/search-list">Search List</Link></li>
                            <li><Link to="/search-grid">Search Grid</Link></li>
                            <li><Link to="/faq">FAQ</Link></li>
                            <li><Link to="/pricing">Pricing</Link></li>
                            <li><Link to="/user-list">User List</Link></li>
                            <li><Link to="/Registration">Registration</Link></li>
                            <li><Link to="/error">404</Link></li>
                          </ul>
                        </li> */}
                        {/* <li className="menu-item-has-children">
                          <a href="#">News</a>
                          <ul className="sub-menu">
                            <li><Link to="/news">News</Link></li>
                            <li><Link to="/news-details">News Details</Link></li>
                          </ul>
                        </li> */}
                        {/* <li><Link to="/contact">Contact Us</Link></li> */}
                      </ul>
                    </div>
                    <div className="nav-right-part nav-right-part-desktop readeal-top">
                     {loginName === null || loginName === "" ?
                    <Link className="btn btn-yellow" to="/registration">LOGIN / SIGNUP <span className="right"><i className="la la-user" /></span></Link> : null }
                    </div>
                  </div>
                </nav>
              </div>
            </div>
        )
    }
}


export default Navbar