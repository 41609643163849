import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import FaqSection from './section-components/faq';
import Footer from './global-components/footer';

const Contact = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Contact" />
        <div className="contact-area pd-top-100 pd-bottom-65">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-8">
		        <div className="contact-page-map">
		          <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.821317897827!2d75.69410691298486!3d13.849761772245941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbb000e241932e1%3A0x68651a05242675f1!2sRuchi%20Complex!5e0!3m2!1sen!2sin!4v1665830427933!5m2!1sen!2sin" style={{border: 0}} allowFullScreen />
		        </div>
		      </div>
		      <div className="col-lg-4">
		        <form className="contact-form-wrap contact-form-bg">
		          <h4>Contact Now</h4>
		          <div className="rld-single-input">
		            <input type="text" placeholder="Name" />
		          </div>
		          <div className="rld-single-input">
		            <input type="text" placeholder="Phone" />
		          </div>
		          <div className="rld-single-input">
		            <input type="text" placeholder="Phone" />
		          </div>
		          <div className="rld-single-input">
		            <textarea rows={10} placeholder="Message" defaultValue={""} />
		          </div>
		          <div className="btn-wrap text-center">
		            <button className="btn btn-yellow">Submit</button>
		          </div>
		        </form>
		      </div>
		    </div>
		    <div className="row pd-top-92">
			<div className="col-xl-3 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-user" />Name:</p>
		          <h6>DEEPIKA M</h6>
				  <p>FOUNDER & CEO</p>
		        </div>
		      </div>
		      <div className="col-xl-3 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-phone" />Call Us:</p>
		          <h6>7259590068</h6>
		        </div>
		      </div>
		      <div className="col-xl-3 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-envelope" />Have any Question?</p>
		          <p><b>Shimogaproperties05@gmail.com</b></p>
		        </div>
		      </div>
		      <div className="col-xl-3 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-phone" />Address</p>
		          <h6>Ruchi complex, Bhadravathi</h6>
		          {/* <h5>CA 95046, United States</h5> */}
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
        <Footer />
    </div>
}

export default Contact

