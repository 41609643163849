import firebase from 'firebase';
import React from 'react';
import { Link } from 'react-router-dom';

class UserAds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firebaseData: []
        }
    }

    componentDidMount() {
        let comp = this
        let loginId = localStorage.getItem("loginId");

        firebase.firestore().collection("Property")
        .where("UserId", "==", loginId)
        .onSnapshot(function(snap) {
            let firebaseData = []
            snap.forEach(function(snap){
                firebaseData.push(snap.data())
            })
            comp.setState({
                firebaseData: firebaseData
            })
        })
    }
    render() {

        let imagealt = 'image'

        return (
            <div className="properties-area pd-top-92">
                <div className="container">
                <div className="row">

                {this.state.firebaseData.length == 0 ? <>
                    <h5 className="title">There are no ads posted by you</h5> 
                    <br /> <br /> 
                    </>: 
                null }

                { this.state.firebaseData.reverse().map( ( item, i )=>
                            <div key={ i } className={"rld-filter-item  col-lg-3 col-sm-6 "+item.cat}>
                                <div className="single-feature">
                                <div className="thumb">
                                    <img src={ item.Image1 } height = "250" alt="img" />
                                </div>
                                <div className="details">
                                    <a href="#" className="feature-logo">
                                    <img src={"/assets/img/icons/l4.png" } alt={ imagealt } />
                                    </a>
                                    <p className="author"><i className="fa fa-user" /> { item.Name }</p>
                                    <h6 className="title readeal-top"><Link to={ item.Url }>{ item.ProjectName }</Link></h6>
                                    <h6 className="price">{ "₹ " +item.Price }</h6>
                                    <ul className="info-list">
                                        <li key={ i } ><i className= "fa fa-bed" /> { item.Bedrooms }</li>
                                        <li key={ i } ><i className= "fa fa-bath" /> { item.Bathrooms + " Bath" }</li>
                                    <li><img src={"/assets/img/icons/7.png" } alt={ imagealt } /> { item.PropertySize + " Sqft" }</li>
                                    </ul>
                                    <ul className="contact-list">
                                    <li><a className="phone" href="#"><i className="fa fa-phone" /></a></li>
                                    <li><a className="message" href="#"><img src={ "/assets/img/icons/8.png" } alt="img" /></a></li>
                                    <li className="readeal-top"><Link className="btn btn-yellow" to= {{
                                        pathname: "/property-details/" + item.PropertyId, 
                                        itemData: item 
                                    }}>View Details</Link></li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        ) }

                </div>
                </div>
            </div>
        )
    }
}

export default UserAds