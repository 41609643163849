import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertySection from './section-components/properties-by-city';
import TopAuthor from './section-components/top-author';
import Footer from './global-components/footer';

const AvailableProperty = (props) => {
    let cityId = ""
    let cityName = ""
    if(props.location.city !==  undefined) {
        cityId = props.location.city.pushId
        cityName = props.location.city.name
    }
    return <div>
        <Navbar />
        <PageHeader headertitle="Properties by City"/>
        <PropertySection cityId = {cityId} cityName = {cityName}  />
        {/* <TopAuthor /> */}
        <Footer />
    </div>
}

export default AvailableProperty

