import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import firebase from '../../firebase';

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

class Builders extends Component {
    constructor(props) {
      super(props);
      this.state = {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        companyName: '',
        password: '',
        confirmPassword: '',
        type: 'Builder'
      }
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }

    register = async (e) => {
      let data = this.state;
      let comp = this;

      if(data.type === "") {
        alert("Select Type");
        document.getElementById("type").focus();
        return;
      }

      if(data.firstName === "") {
        alert("Enter First Name");
        document.getElementById("firstName").focus();
        return;
      }

      if(data.lastName === "") {
        alert("Enter Last Name");
        document.getElementById("lastName").focus();
        return;
      }

      if(data.companyName === "") {
        alert("Enter Company Name");
        document.getElementById("companyName").focus();
        return;
      }

      if(data.mobileNumber === "") {
        alert("Enter Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
      }

      
      if(data.mobileNumber.length !== 10) {
        alert("Enter Correct Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
      }

      
      if(data.password === "") {
        alert("Enter Password");
        document.getElementById("password").focus();
        return;
      }

      
      if(data.confirmPassword === "") {
        alert("Enter Confirm Password");
        document.getElementById("confirmPassword").focus();
        return;
      }

      
      if(data.password !== data.confirmPassword) {
        alert("Password Doesn't match. Please enter correct password ");
        document.getElementById("confirmPassword").focus();
        return;
      }

      var today = new Date()

        const uniqueId = (
          await firebase.firestore().collection("UniqueId").doc("Agents").get()
        ).data();
        const nextId = "SPAG" + (uniqueId.Id + 1);


        await firebase.firestore().collection("Agents").doc(nextId).set({
            JoiningDate: formatDate(today),
            Type: data.type,
            Name: data.firstName + " " + data.lastName,
            FirstName: data.firstName,
            LastName: data.lastName,
            CompanyName: data.companyName,
            MobileNumber: "+91" + data.mobileNumber,
            Password: data.password,
            Status: "Active",
            ApprovalStatus: "Pending",
            UserId: nextId
          })
          .then(() => {
            firebase.firestore().collection("UniqueId").doc("Agents").update({
                Id: firebase.firestore.FieldValue.increment(1),
              });

            alert("Registration Done Successfully!!")
            comp.setState({
              firstName: '',
              lastName: '',
              mobileNumber: '',
              companyName: '',
              password: '',
              confirmPassword: '',
              type: 'Builder'
            })
          })
          .catch((error) => {
            console.log(error)
            alert("Unable to do registration. Please try after Sometime!!")
          });
    }


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.whychooseus


    return <div className="register-page-area pd-bottom-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="contact-form-wrap contact-form-bg">
                  <h4>Builders</h4>
                  {/* <div className="rld-single-select">
                      <select className="select single-select" disabled  name = "type"  id = "type" onChange = {this.handleChange} value = {this.state.type}>
                        <option value= "">Select Type</option>
                        <option value= "Agent">Agent</option>
                        <option value= "Builder">Builder</option>
                        <option value= "Owner">Owner</option>
                      </select>
                  </div> */}
                  <div className="rld-single-input">
                    <input type="text" name = "firstName" id = "firstName" onChange={this.handleChange} value = {this.state.firstName} placeholder="First Name" />
                  </div>
                  <div className="rld-single-input">
                    <input type="text"  name = "lastName" id = "lastName" onChange={this.handleChange} value = {this.state.lastName} placeholder="Last Name" />
                  </div>
                  <div className="rld-single-input">
                    <input type="text"  name = "companyName" id = "companyName" onChange={this.handleChange} value = {this.state.companyName} placeholder="Company Name" />
                  </div>
                  <div className="rld-single-input">
                    <input type="text"  name = "mobileNumber" id = "mobileNumber" onChange={this.handleChange} value = {this.state.mobileNumber} placeholder="Mobile Number" />
                  </div>
                  <div className="rld-single-input">
                    <input type="password"  name = "password" id = "password" onChange={this.handleChange} value = {this.state.password} placeholder="Password" />
                  </div>
                  <div className="rld-single-input">
                    <input type="password"  name = "confirmPassword" id = "confirmPassword" onChange={this.handleChange} value = {this.state.confirmPassword} placeholder="Confirm Password" />
                  </div>
                  <div className="btn-wrap">
                    <button onClick={this.register} className="btn btn-yellow">Register</button>
                  </div>
                  {/* <ul className="social-icon">
                    <li className="ml-0">
                      <a href="#" target="_blank"><i className="fa fa-facebook  " /></a>
                    </li>
                    <li>
                      <a href="#" target="_blank"><i className="fa fa-twitter  " /></a>
                    </li>
                    <li>
                      <a href="#" target="_blank"><i className="fa fa-linkedin" /></a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        }
}

export default Builders