import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import firebase from '../../firebase';

class Property extends Component {
  constructor(props) {
    super(props);
    this.state = {
        firebaseData: [],
        locations: [],
        cityName: '',
        city: ''
    }
  }

  componentDidMount() {
    let comp = this;

    firebase.firestore().collection("Property")
    .where("ApprovalStatus", "==", "Approved")
    .limit(12)
    .onSnapshot(function(querySnapshot) {
        var properties = []
        querySnapshot.forEach(function(doc) {
          properties.push(doc.data())
      })
      comp.setState({
        firebaseData: properties
      })
    })
  }


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.propertybtcities


    return <div className="properties-area pd-top-92">
        <div className="container">
          <div className="row">

          { this.state.firebaseData.reverse().map( ( item, i )=>
                      <div key={ i } className={"rld-filter-item  col-lg-3 col-sm-6 "+item.cat}>
                        <div className="single-feature">
                          <div className="thumb">
                             <img src={ item.Image1 } height = "250" alt="img" />
                          </div>
                          <div className="details">
                            <a href="#" className="feature-logo">
                               <img src={"/assets/img/icons/l4.png" } alt={ imagealt } />
                            </a>
                            <p className="author"><i className="fa fa-user" /> { item.Name }</p>
                            <h6 className="title readeal-top"><Link to={ item.Url }>{ item.ProjectName }</Link></h6>
                            <h6 className="price">{ "₹ " +item.Price }</h6>
                            <ul className="info-list">
                                <li key={ i } ><i className= "fa fa-bed" /> { item.Bedrooms }</li>
                                <li key={ i } ><i className= "fa fa-bath" /> { item.Bathrooms + " Bath" }</li>
                              <li><img src={"/assets/img/icons/7.png" } alt={ imagealt } /> { item.PropertySize + " Sqft" }</li>
                            </ul>
                            <ul className="contact-list">
                              <li><a className="phone" href="#"><i className="fa fa-phone" /></a></li>
                              <li><a className="message" href="#"><img src={ "/assets/img/icons/8.png" } alt="img" /></a></li>
                              <li className="readeal-top"><Link className="btn btn-yellow" to= {{
                                 pathname: "/property-details/" + item.PropertyId, 
                                 itemData: item 
                              }}>View Details</Link></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                   ) }

          </div>
        </div>
      </div>


        }
}

export default Property