import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import firebase from '../firebase';
import UserDetails from './section-components/userDetails';

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
        }
    }

    componentDidMount() {
        let comp = this;

        var loginId = localStorage.getItem("loginId")
        let userData = []

        firebase.firestore().collection("Users").doc(loginId)
        .onSnapshot(function(snap) {
            comp.setState({
                userData: snap.data()
            })
        })
    }

    render() {
        {console.log(this.props.location.pathname)}
        let pathName = this.props.location.pathname

        let activeTab = "Profile"
        if(pathName == "/profile/user") {
            activeTab = "Profile"
        }

        if(pathName == "/profile/ads") {
            activeTab = "Ads"
        }

        if(pathName == "/profile/inbox") {
            activeTab = "Inbox"
        }

        if(pathName == "/profile/membership") {
            activeTab = "Membership"
        }
        return (
        <div>
            <Navbar />
            <PageHeader headertitle="User Dashboard" subheader="Profile" />
            <UserDetails activeTab = {activeTab} userData = {this.state.userData} />
            <Footer />
        </div>
        )
    }
}

export default User

