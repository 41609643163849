import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import firebase from '../../firebase';

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

class AddNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
        propertyFor: '',
        propertyType: '',
        cities: [],
        city: '',
        cityName: '',
        location: '',
        projectName: '',
        address: '',
        resaleProperty: '',
        numberOfFloors: '',
        propertyOnTheFloor: '',
        furnishedStatus: '',
        facing: '',
        propertyAge: '',
        ownership: '',
        avalability: '',
        bedrooms: '',
        bathrooms: '',
        balconies: '',
        propertySize: '',
        possessionBy: '',
        price: '',
        bookingAmount: '',
        maintainanceCharges: '',
        projectArea: '',
        registrationNumber: '',
        projectDescription: '',
        businessType: '',
        amen1: false,
        amen2: false,
        amen3: false,
        amen4: false,
        amen5: false,
        amen6: false,
        amen7: false,
        amen8: false,
        amen9: false,
        amenA: false,
        amenB: false,
        amenC: false,
        amenD: false,
        amenE: false,
        amenF: false,
        amenG: false,
        amenH: false,
        amenI: false,
        amenJ: false,
        amenK: false,
        amenL: false,
        image1: '',
        image2: '',
        image3: ''
    }
  }

  componentDidMount() {
    let comp = this;

    firebase.firestore().collection("Location")
    .onSnapshot(function(querySnapshot) {
        var cities = []
        querySnapshot.forEach(function(doc) {
          cities.push(doc.data())
      })
      comp.setState({
        cities: cities
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    let data =  this.state
    let comp = this

    if(data.propertyFor == "") {
      alert("Select Property For")
      document.getElementById("propertyFor").focus();
      return
    }

    if(data.propertyType == "") {
      alert("Select Property Type")
      document.getElementById("propertyType").focus();
      return
    }

    if(data.city == "") {
      alert("Select City")
      document.getElementById("city").focus();
      return
    }

    if(data.projectName == "") {
      alert("Enter Project Name")
      document.getElementById("projectName").focus();
      return
    }

    if(data.address == "") {
      alert("Enter Address")
      document.getElementById("address").focus();
      return
    }

    if(data.propertySize == "") {
      alert("Enter Property Size")
      document.getElementById("propertySize").focus();
      return
    }

    if(data.price == "") {
      alert("Enter Price")
      document.getElementById("price").focus();
      return
    }

    if(data.bookingAmount == "") {
      alert("Enter Booking Amount")
      document.getElementById("bookingAmount").focus();
      return
    }

    if(data.maintainanceCharges == "") {
      alert("Enter Maintainance Charges")
      document.getElementById("maintainanceCharges").focus();
      return
    }

    if(data.projectArea == "") {
      alert("Enter Project Area")
      document.getElementById("projectArea").focus();
      return
    }

    if(data.registrationNumber == "") {
      alert("Enter Registration Number")
      document.getElementById("registrationNumber").focus();
      return
    }

     let amenities = ''
    if(data.amen1) {
        amenities+= "amen1" + ","
    }

    if(data.amen2) {
      amenities+= "amen2" + ","
    }

    if(data.amen3) {
      amenities+= "amen3" + ","
    }

    if(data.amen4) {
        amenities+= "amen4" + ","
    }

    if(data.amen5) {
        amenities+= "amen5" + ","
    }

    if(data.amen6) {
        amenities+= "amen6" + ","
    }

    if(data.amen7) {
        amenities+= "amen7" + ","
    }

    if(data.amen8) {
        amenities+= "amen8" + ","
    }

    if(data.amen9) {
        amenities+= "amen9" + ","
    }

    if(data.amenA) {
        amenities+= "amenA" + ","
    }

    if(data.amenB) {
        amenities+= "amenB" + ","
    }

    if(data.amenC) {
        amenities+= "amenC" + ","
    }

    if(data.amenD) {
        amenities+= "amenD" + ","
    }

    if(data.amenE) {
        amenities+= "amenE" + ","
    }

    if(data.amenF) {
      amenities+= "amenF" + ","
    }

    if(data.amenG) {
      amenities+= "amenG" + ","
    }

    if(data.amenH) {
        amenities+= "amenH" + ","
    }

    if(data.amenI) {
        amenities+= "amenI" + ","
    }

    if(data.amenJ) {
        amenities+= "amenJ" + ","
    }

    if(data.amenK) {
        amenities+= "amenK" + ","
    }

    if(data.amenL) {
      amenities+= "amenL" + ","
    }

    console.log(amenities)
    var today = new Date()

    let pushid = ""

    var userId = localStorage.getItem("loginId")
    var userName = localStorage.getItem("loginName")

    // Url: /property-details
    // CityLocation: '',
    // Latitude: '',
    // Longgitude: ''
    // Address placeholder: ''

    firebase.firestore().collection("Property").add({
      PropertyFor: data.propertyFor,
      PropertyType: data.propertyType,
      City: data.city,
      CityName: data.cityName,
      ProjectName: data.projectName,
      Address: data.address,
      ResaleProperty: data.resaleProperty,
      NumberOfFloors: data.numberOfFloors,
      PropertyOnTheFloor: data.propertyOnTheFloor,
      FurnishedStatus: data.furnishedStatus,
      Facing: data.facing,
      BusinessType: data.businessType,
      PropertyAge: data.propertyAge,
      Ownership: data.ownership,
      Avalability: data.avalability,
      Bedrooms: data.bedrooms,
      Bathrooms: data.bathrooms,
      Balconies: data.balconies,
      PropertySize: data.propertySize,
      PossessionBy: data.possessionBy,
      Price: data.price,
      BookingAmount: data.bookingAmount,
      MaintainanceCharges: data.maintainanceCharges,
      ProjectArea: data.projectArea,
      RegistrationNumber: data.registrationNumber,
      ProjectDescription: data.projectDescription,
      Amenities: amenities,
      Image1: data.image1,
      Image2: data.image2,
      Image3: data.image3,
      PostedDate: formatDate(today),
      UserId: userId,
      Name: userName,
      Status: "Active",
      ApprovalStatus: "Pending",
      Location: data.location,
      Image1: data.image1,
      Image2: data.image2,
      Image3: data.image3,
      Featured: "NO",
      PropertyId: Date.now()
    })
    .then((docRef) => {
      pushid = docRef.id

      firebase.firestore().collection('Property').doc(pushid).update({
        PushId: pushid
    })

      alert("Property Added Successfully!!")
      comp.setState({
        propertyFor: '',
        propertyType: '',
        city: '',
        cityName: '',
        location: '',
        projectName: '',
        address: '',
        resaleProperty: '',
        numberOfFloors: '',
        propertyOnTheFloor: '',
        furnishedStatus: '',
        facing: '',
        propertyAge: '',
        ownership: '',
        avalability: '',
        bedrooms: '',
        bathrooms: '',
        balconies: '',
        propertySize: '',
        possessionBy: '',
        price: '',
        bookingAmount: '',
        maintainanceCharges: '',
        projectArea: '',
        registrationNumber: '',
        projectDescription: '',
        businessType: '',
        amen1: false,
        amen2: false,
        amen3: false,
        amen4: false,
        amen5: false,
        amen6: false,
        amen7: false,
        amen8: false,
        amen9: false,
        amenA: false,
        amenB: false,
        amenC: false,
        amenD: false,
        amenE: false,
        amenF: false,
        amenG: false,
        amenH: false,
        amenI: false,
        amenJ: false,
        amenK: false,
        amenL: false,
        image1: '',
        image2: '',
        image3: ''
      })
    })
    .catch((error) => {
      console.log(error)
      alert("Unabel to post property. Please try after Sometime!!")
    });





  }

  imageUpload1 = e =>{
        
    if(e.target.files[0] === 0){
       alert("Add Photo ");
       return;
   }
  
   const ref = firebase.storage().ref("/Property/");
   const file = e.target.files[0];
   const name = e.target.files[0] + Date();
   const metadata = {
   contentType: file.type
   };
   const task = ref.child(name).put(file, metadata);
   task
   .then(snapshot => snapshot.ref.getDownloadURL())
   .then((url) => {
     this.setState({
       image1: url
     })
   })
   .catch(console.error);         
  }

  imageUpload2 = e =>{
        
    if(e.target.files[0] === 0){
       alert("Add Photo ");
       return;
   }
  
   const ref = firebase.storage().ref("/Property/");
   const file = e.target.files[0];
   const name = e.target.files[0] + Date();
   const metadata = {
   contentType: file.type
   };
   const task = ref.child(name).put(file, metadata);
   task
   .then(snapshot => snapshot.ref.getDownloadURL())
   .then((url) => {
     this.setState({
       image2: url
     })
   })
   .catch(console.error);         
  }

  imageUpload3 = e =>{
        
    if(e.target.files[0] === 0){
       alert("Add Photo ");
       return;
   }
  
   const ref = firebase.storage().ref("/Property/");
   const file = e.target.files[0];
   const name = e.target.files[0] + Date();
   const metadata = {
   contentType: file.type
   };
   const task = ref.child(name).put(file, metadata);
   task
   .then(snapshot => snapshot.ref.getDownloadURL())
   .then((url) => {
     this.setState({
       image3: url
     })
   })
   .catch(console.error);         
  }

  cityOnChange = (e) => {
    var cityPushId = e.target.value;
    let comp =  this

    if(cityPushId !== "") {
      firebase.firestore().collection("Location").doc(cityPushId)
      .onSnapshot(function(snap) {
          comp.setState({
            city: cityPushId,
            cityName: snap.data().Name,
            location: snap.data().Location
          })
      })
    }
  }

    render() {

      console.log("Property Type", this.state.propertyType)

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.aboutus


    return <div className="add-new-property-area pd-top-90 mg-bottom-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10">
                <div className="section-title text-center">
                  <h3>Add New Property</h3>
                </div>
                <div className="border-bottom mb-4">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">
                          1
                        </div>
                        <div className="details">
                          <h4 className="title">Choose Listing</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">
                          2
                        </div>
                        <div className="details">
                          <h4 className="title">Add Information</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">
                          3
                        </div>
                        <div className="details">
                          <h4 className="title">Publish</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-5">
                    <div className="section-title mb-md-0">
                      <h4 className="pt-lg-1 pt-2">Categories</h4>
                    </div>
                  </div>
                  <div className="col-6 text-right add-property-btn-wrap">
                    <div className="rld-single-select">
                          <select className="select single-select">
                            <option value={1}>Property For</option>
                            <option value={2}>Sell</option>
                            <option value={3}>Rent</option>
                            <option value={3}>Lease</option>
                          </select>
                      </div>
                      <div className="rld-single-select">
                          <select className="select single-select">
                              <option value={1}>Property Type</option>
                              <option value={2}>Flat</option>
                              <option value={3}>House/Villa</option>
                              <option value={3}>Plot/Site</option>
                              <option value={3}>Shop/Showroom</option>
                              <option value={3}>Office Spaces</option>
                              <option value={3}>Commercial Land</option>
                              <option value={3}>Agricultural Land</option>
                          </select>
                      </div>
                    <a className="btn btn-yellow mr-md-3" href="#">Sell</a>
                    <a className="btn btn-yellow" href="#">Rent</a>
                  </div>
                </div> */}

                <div className="row pd-top-100">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4><img src={publicUrl+"assets/img/icons/28.png" }alt="img" />Categories</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "propertyFor"  id = "propertyFor" onChange = {this.handleChange} value = {this.state.propertyFor} className="select single-select">
                              <option value= "">Property For</option>
                              <option value= "Sell">Sell</option>
                              <option value= "Rent">Rent</option>
                              <option value= "Lease">Lease</option>
                            </select>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "propertyType"  id = "propertyType" onChange = {this.handleChange} value = {this.state.propertyType} className="select single-select">
                              <option value= "">Property Type</option>
                              <option value= "Flat">Flat</option>
                              <option value= "House">House/Villa</option>
                              <option value= "Plot">Plot/Site</option>
                              <option value= "Shop">Shop/Showroom</option>
                              <option value= "Office Spaces">Office Spaces</option>
                              <option value= "Commercial Land">Commercial Land</option>
                              <option value= "Agricultural Land">Agricultural Land</option>
                            </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row pd-top-60">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4><img src={publicUrl+"assets/img/icons/28.png" }alt="img" />City / Locality</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "city"  id = "city" onChange = {this.cityOnChange} value = {this.state.city} className="select single-select">
                            <option value= "">Select City</option>
                            {this.state.cities.map((item, key) => (
                               <option value={item.PushId}>{item.Name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row pd-top-60">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4><img src={publicUrl+"assets/img/icons/28.png" }alt="img" />Project Name:</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="rld-single-input">
                        <input type = "text"  name = "projectName"  id = "projectName" onChange = {this.handleChange} value = {this.state.projectName} style = {{border: "none", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="Project Name" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row pd-top-60">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4><img src={publicUrl+"assets/img/icons/28.png" }alt="img" />Address</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    {/* <div className="section-title">
                      <h4>Jason Landville Apartments</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing </p>
                    </div> */}
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="rld-single-input">
                        <input type = "text" name = "address"  id = "address" onChange = {this.handleChange} value = {this.state.address}  style = {{border: "none", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="Address" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row pd-top-60">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4><img src={publicUrl+"assets/img/icons/28.png" }alt="img" />Property Details:</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "resaleProperty"  id = "resaleProperty" onChange = {this.handleChange} value = {this.state.resaleProperty} className="select single-select">
                            <option value = "">Resale Property</option>
                            <option value = "New Sale">New Sale</option>
                            <option value = "Rent">Rent</option>
                            <option value = "Lease">Lease</option>
                          </select>
                        </div>
                      </div>

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" || this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?

                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "numberOfFloors"  id = "numberOfFloors" onChange = {this.handleChange} value = {this.state.numberOfFloors} className="select single-select">
                            <option value= "">Number of Floors:</option>
                            <option value= "Ground">Ground</option>
                            <option value= "1">1</option>
                            <option value= "2">2</option>
                            <option value= "3">3</option>
                            <option value= "4">4</option>
                            <option value= "5">5</option>
                            <option value= "6">6</option>
                            <option value= "7">7</option>
                            <option value= "8">8</option>
                            <option value= "9">9</option>
                            <option value= "10">10</option>
                            <option value= "11">11</option>
                            <option value= "12">12</option>
                            <option value= "13">13</option>
                            <option value= "14">14</option>
                            <option value= "15">15</option>
                            <option value= "16">16</option>
                            <option value= "17">17</option>
                            <option value= "18">18</option>
                            <option value= "19">19</option>
                            <option value= "20">20</option>
                            <option value= "21">21</option>
                            <option value= "22">22</option>
                            <option value= "23">23</option>
                            <option value= "24">24</option>
                            <option value= "25">25</option>
                            <option value= "26">26</option>
                            <option value= "27">27</option>
                            <option value= "28">28</option>
                            <option value= "29">29</option>
                            <option value= "30">30</option>
                            <option value= "31">31</option>
                            <option value= "32">32</option>
                            <option value= "33">33</option>
                            <option value= "34">34</option>
                            <option value= "35">35</option>
                            <option value= "36">36</option>
                            <option value= "37">37</option>
                            <option value= "38">38</option>
                            <option value= "39">39</option>
                            <option value= "40">40</option>
                            <option value= "41">41</option>
                            <option value= "42">42</option>
                            <option value= "43">43</option>
                            <option value= "44">44</option>
                            <option value= "45">45</option>
                            <option value= "46">46</option>
                            <option value= "47">47</option>
                            <option value= "48">48</option>
                            <option value= "49">49</option>
                            <option value= "50">50</option>
                          </select>
                        </div>
                      </div> : null }

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" || this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "propertyOnTheFloor"  id = "propertyOnTheFloor" onChange = {this.handleChange} value = {this.state.propertyOnTheFloor} className="select single-select">
                            <option value={1}>Property on the Floor:</option>
                            <option value= "Ground">Ground</option>
                            <option value= "1">1</option>
                            <option value= "2">2</option>
                            <option value= "3">3</option>
                            <option value= "4">4</option>
                            <option value= "5">5</option>
                            <option value= "6">6</option>
                            <option value= "7">7</option>
                            <option value= "8">8</option>
                            <option value= "9">9</option>
                            <option value= "10">10</option>
                            <option value= "11">11</option>
                            <option value= "12">12</option>
                            <option value= "13">13</option>
                            <option value= "14">14</option>
                            <option value= "15">15</option>
                            <option value= "16">16</option>
                            <option value= "17">17</option>
                            <option value= "18">18</option>
                            <option value= "19">19</option>
                            <option value= "20">20</option>
                            <option value= "21">21</option>
                            <option value= "22">22</option>
                            <option value= "23">23</option>
                            <option value= "24">24</option>
                            <option value= "25">25</option>
                            <option value= "26">26</option>
                            <option value= "27">27</option>
                            <option value= "28">28</option>
                            <option value= "29">29</option>
                            <option value= "30">30</option>
                            <option value= "31">31</option>
                            <option value= "32">32</option>
                            <option value= "33">33</option>
                            <option value= "34">34</option>
                            <option value= "35">35</option>
                            <option value= "36">36</option>
                            <option value= "37">37</option>
                            <option value= "38">38</option>
                            <option value= "39">39</option>
                            <option value= "40">40</option>
                            <option value= "41">41</option>
                            <option value= "42">42</option>
                            <option value= "43">43</option>
                            <option value= "44">44</option>
                            <option value= "45">45</option>
                            <option value= "46">46</option>
                            <option value= "47">47</option>
                            <option value= "48">48</option>
                            <option value= "49">49</option>
                            <option value= "50">50</option>
                          </select>
                        </div>
                      </div>: null }

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" || this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "furnishedStatus"  id = "furnishedStatus" onChange = {this.handleChange} value = {this.state.furnishedStatus} className="select single-select">
                            <option value= "">Furnished Status::</option>
                            <option value= "Furnished">Furnished</option>
                            <option value= "Semi-Furnished">Semi-Furnished</option>
                            <option value= "Unfurnished">Unfurnished</option>
                          </select>
                        </div>
                      </div> : null }

                      {this.state.propertyType !== "Agricultural Land" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "facing"  id = "facing" onChange = {this.handleChange} value = {this.state.facing}  className="select single-select">
                            <option value= "">Facing:</option>
                            <option value= "North">North</option>
                            <option value= "South">South</option>
                            <option value= "East">East</option>
                            <option value= "West">West</option>
                            <option value= "North-East">North-East</option>
                            <option value= "South-East">South-East</option>
                            <option value= "North-West">North-West</option>
                            <option value= "South-West">South-West</option>
                          </select>
                        </div>
                      </div>: null }

                      {this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "businessType"  id = "businessType" onChange = {this.handleChange} value = {this.state.businessType} className="select single-select">
                            <option value= "">Business Type:</option>
                            <option value= "Any">Any</option>
                            <option value= "Clinic">Clinic</option>
                            <option value= "Clothes Shop">Clothes Shop</option>
                            <option value= "Footwear Shop">Footwear Shop</option>
                            <option value= "Grocery Shop">Grocery Shop</option>
                            <option value= "Gym">Gym</option>
                            <option value= "Hotel">Hotel/Restaurant</option>
                            <option value= "Jewellery Shop">Jewellery Shop</option>
                            <option value= "Meat Shop">Meat Shop</option>
                            <option value= "Mobile Shop">Mobile Shop</option>
                            <option value= "Salon">Salon/Spa</option>
                            <option value= "Stationary Shop">Stationary Shop</option>
                          </select>
                        </div>
                      </div> : null }

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" || this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select  name = "propertyAge"  id = "propertyAge" onChange = {this.handleChange} value = {this.state.propertyAge} className="select single-select">
                            <option value= "">Property Age:</option>
                            <option value= "1 to 3 Years">1 to 3 Years</option>
                            <option value= "3 to 5 Years">3 to 5 Years</option>
                            <option value= "5 to 10 Years">5 to 10 Years</option>
                            <option value= "10+ Years">10+ Years</option>
                          </select>
                        </div>
                      </div>: null }

                    
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "ownership"  id = "ownership" onChange = {this.handleChange} value = {this.state.ownership}  className="select single-select">
                            <option value= "">Ownership:</option>
                            <option value= "Owner">Owner</option>
                            <option value= "Agent">Agent</option>
                            <option value= "Builder">Builder/Promoter</option>
                          </select>
                        </div>
                      </div>

                      {this.state.propertyType !== "Agricultural Land" && this.state.propertyType !== "Plot" && this.state.propertyType !== "Commercial Land" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "avalability"  id = "avalability" onChange = {this.handleChange} value = {this.state.avalability}  className="select single-select">
                            <option value= "">Availability:</option>
                            <option value= "Ready to Move">Ready to Move</option>
                            <option value= "Under Construction">Under Construction</option>
                            <option value= "Upcoming">Upcoming</option>
                          </select>
                        </div>
                      </div>:  null }

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select  name = "bedrooms"  id = "bedrooms" onChange = {this.handleChange} value = {this.state.bedrooms} className="select single-select">
                            <option value= "">Bedrooms:</option>
                            <option value= "1 RK">1 RK</option>
                            <option value= "1 BHK">1 BHK</option>
                            <option value= "2 BHK">2 BHK</option>
                            <option value= "3 BHK">3 BHK</option>
                            <option value= "4 BHK">4 BHK</option>
                            <option value= "4+ BHK">4+ BHK</option>
                          </select>
                        </div>
                      </div>: null}

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" || this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "bathrooms"  id = "bathrooms" onChange = {this.handleChange} value = {this.state.bathrooms}  className="select single-select">
                            <option value= "">Bathrooms:</option>
                            <option value= "1">1</option>
                            <option value= "2">2</option>
                            <option value= "3">3</option>
                            <option value= "4">4</option>
                            <option value= "4+">4+</option>
                          </select>
                        </div>
                      </div>: null }

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select name = "balconies"  id = "balconies" onChange = {this.handleChange} value = {this.state.balconies}  className="select single-select">
                            <option value= "">Balconies:</option>
                            <option value= "1">1</option>
                            <option value= "2">2</option>
                            <option value= "3">3</option>
                            <option value= "4">4</option>
                            <option value= "4+">4+</option>
                          </select>
                        </div>
                      </div>: null }
                    </div>
                  </div>
                </div>

                <div className="row pd-top-60">
                  <div className="col-md-4">
                    <div className="section-title">
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="section-title">
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-input">
                          <label style = {{color: "black"}}>Property Size (In terms of SqFt)</label>
                          <input name = "propertySize"  id = "propertySize" onChange = {this.handleChange} value = {this.state.propertySize}  type = "number" style = {{border: "none", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="" />
                        </div>
                      </div>

                      {this.state.propertyType !== "Agricultural Land" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-input">
                          <label style = {{color: "black"}}>Possession By:</label>
                          <input name = "possessionBy"  id = "possessionBy" onChange = {this.handleChange} value = {this.state.possessionBy}  type = "text" style = {{border: "none", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="" />
                        </div>
                      </div>: null }

                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-input">
                          <label style = {{color: "black"}}>Price:</label>
                          <input name = "price"  id = "price" onChange = {this.handleChange} value = {this.state.price}  type = "number" style = {{border: "none", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="" />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-input">
                          <label style = {{color: "black"}}>Booking Amount:</label>
                          <input name = "bookingAmount"  id = "bookingAmount" onChange = {this.handleChange} value = {this.state.bookingAmount}  type = "number" style = {{border: "none", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="" />
                        </div>
                      </div>

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" || this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-input">
                          <label style = {{color: "black"}}>Maintenance Charges:</label>
                          <input name = "maintainanceCharges"  id = "maintainanceCharges" onChange = {this.handleChange} value = {this.state.maintainanceCharges} type = "text" style = {{border: "none", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="" />
                        </div>
                      </div>: null }

                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-input">
                          <label style = {{color: "black"}}>Total Project Area: (In terms of SqFt)</label>
                          <input name = "projectArea"  id = "projectArea" onChange = {this.handleChange} value = {this.state.projectArea}  type = "number" style = {{border: "none", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="" />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="rld-single-input">
                          <label style = {{color: "black"}}>RERA Registration Number:</label>
                          <input name = "registrationNumber"  id = "registrationNumber" onChange = {this.handleChange} value = {this.state.registrationNumber}  type = "text" style = {{border: "none", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="" />
                        </div>
                      </div>

                      <div className="col-12 mb-2">
                        <div className="rld-single-input">
                        <label style = {{color: "black"}}>Project Description</label>
                          <textarea name = "projectDescription"  id = "projectDescription" onChange = {this.handleChange} value = {this.state.projectDescription}  rows={10} placeholder=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {/* <div className="row pd-top-80">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4><img src={publicUrl+"assets/img/icons/29.png"} alt="img" />Address</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="section-title">
                      <h4>Address Here</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select className="select single-select">
                            <option value={1}>Country</option>
                            <option value={2}>Usa</option>
                            <option value={3}>Canada</option>
                            <option value={3}>China</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select className="select single-select">
                            <option value={1}>State</option>
                            <option value={2}>State 1</option>
                            <option value={3}>State 2</option>
                            <option value={3}>State 3</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select className="select single-select">
                            <option value={1}>ZIP Code</option>
                            <option value={2}>Interior Size 1</option>
                            <option value={3}>Interior Size 2</option>
                            <option value={3}>Interior Size 3</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="rld-single-select">
                          <select className="select single-select">
                            <option value={1}>Parking</option>
                            <option value={2}>Parking 1</option>
                            <option value={3}>Parking 2</option>
                            <option value={3}>Parking 3</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="rld-single-input">
                          <textarea rows={10} placeholder="Message" defaultValue={""} />
                        </div>
                      </div>
                      <div className="col-12">
                        <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d385276.5794773028!2d-115.51325829849152!3d41.1290219540523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1578810733172!5m2!1sen!2sbd" height={350} style={{border: 0}} allowFullScreen />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="row pd-top-80">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4><img src={publicUrl+"assets/img/icons/30.png"} alt="img" />Lease</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="rld-single-select">
                          <select className="select single-select">
                            <option value={1}>Monthly Rent</option>
                            <option value={2}>$700.00</option>
                            <option value={3}>$500.00</option>
                            <option value={3}>$900.00</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="rld-single-select">
                          <select className="select single-select">
                            <option value={1}>Security Diposite</option>
                            <option value={2}>1 Month</option>
                            <option value={3}>2 Month</option>
                            <option value={3}>3 Month</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="rld-single-select">
                          <select className="select single-select">
                            <option value={1}>Lease Durations</option>
                            <option value={2}>On Set</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row pd-top-80">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4><img src={publicUrl+"assets/img/icons/31.png"} alt="img" />Amenities</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-sm-4">
                        <ul className="rld-list-style mb-3 mb-sm-0">
                          <li><input type = "checkbox" name = "amen1" id = "amen1" onChange={() => { this.setState({ amen1: !this.state.amen1 }) }} checked={this.state.amen1} /> &nbsp; Car Parking</li>
                          <li><input type = "checkbox" name = "amen2" id = "amen2" onChange={() => { this.setState({ amen2: !this.state.amen2 }) }} checked={this.state.amen2} /> &nbsp; Security Services</li>
                          <li><input type = "checkbox" name = "amen3" id = "amen3" onChange={() => { this.setState({ amen3: !this.state.amen3 }) }} checked={this.state.amen3} /> &nbsp; Water Supply</li>
                          <li><input type = "checkbox" name = "amen4" id = "amen4" onChange={() => { this.setState({ amen4: !this.state.amen4 }) }} checked={this.state.amen4} /> &nbsp; Elevators</li>
                          <li><input type = "checkbox" name = "amen5" id = "amen5" onChange={() => { this.setState({ amen5: !this.state.amen5 }) }} checked={this.state.amen5} /> &nbsp; Power backup</li>
                          <li><input type = "checkbox" name = "amen6" id = "amen6" onChange={() => { this.setState({ amen6: !this.state.amen6 }) }} checked={this.state.amen6} /> &nbsp; 24 hour Security</li>
                          <li><input type = "checkbox" name = "amen7" id = "amen7" onChange={() => { this.setState({ amen7: !this.state.amen7 }) }} checked={this.state.amen7} /> &nbsp; Walking Track</li>
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="rld-list-style mb-3 mb-sm-0">
                          <li><input type = "checkbox" name = "amen8" id = "amen8" onChange={() => { this.setState({ amen8: !this.state.amen8 }) }} checked={this.state.amen8} /> &nbsp; Play Area</li>
                          <li><input type = "checkbox" name = "amen9" id = "amen9" onChange={() => { this.setState({ amen9: !this.state.amen9 }) }} checked={this.state.amen9} /> &nbsp; Club House</li>
                          <li><input type = "checkbox" name = "amenA" id = "amenA" onChange={() => { this.setState({ amenA: !this.state.amenA }) }} checked={this.state.amenA} /> &nbsp; Swimming Pool</li>
                          <li><input type = "checkbox" name = "amenB" id = "amenB" onChange={() => { this.setState({ amenB: !this.state.amenB }) }} checked={this.state.amenB} /> &nbsp; Gym</li>
                          <li><input type = "checkbox" name = "amenC" id = "amenC" onChange={() => { this.setState({ amenC: !this.state.amenC }) }} checked={this.state.amenC} /> &nbsp; Rooftop Garden</li>
                          <li><input type = "checkbox" name = "amenD" id = "amenD" onChange={() => { this.setState({ amenD: !this.state.amenD }) }} checked={this.state.amenD} /> &nbsp; Open Deck</li>
                          <li><input type = "checkbox" name = "amenE" id = "amenE" onChange={() => { this.setState({ amenE: !this.state.amenE }) }} checked={this.state.amenE} /> &nbsp; Sky lounge</li>
                        </ul>
                      </div>
                      <div className="col-sm-4">
                        <ul className="rld-list-style mb-3 mb-sm-0">
                          <li><input type = "checkbox" name = "amenF" id = "amenF" onChange={() => { this.setState({ amenF: !this.state.amenF }) }} checked={this.state.amenF} /> &nbsp; Spa and Saloon</li>
                          <li><input type = "checkbox" name = "amenG" id = "amenG" onChange={() => { this.setState({ amenG: !this.state.amenG }) }} checked={this.state.amenG} /> &nbsp; Concierge Services</li>
                          <li><input type = "checkbox" name = "amenH" id = "amenH" onChange={() => { this.setState({ amenH: !this.state.amenH }) }} checked={this.state.amenH} /> &nbsp; Restaurants</li>
                          <li><input type = "checkbox" name = "amenI" id = "amenI" onChange={() => { this.setState({ amenI: !this.state.amenI }) }} checked={this.state.amenI} /> &nbsp; Party hall</li>
                          <li><input type = "checkbox" name = "amenJ" id = "amenJ" onChange={() => { this.setState({ amenJ: !this.state.amenJ }) }} checked={this.state.amenJ} /> &nbsp; Temple and Religious activity place</li>
                          <li><input type = "checkbox" name = "amenK" id = "amenK" onChange={() => { this.setState({ amenK: !this.state.amenK }) }} checked={this.state.amenK} /> &nbsp; Cinema Hall</li>
                          <li><input type = "checkbox" name = "amenL" id = "amenL" onChange={() => { this.setState({ amenL: !this.state.amenL }) }} checked={this.state.amenL} /> &nbsp; Wifi Connectivity</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row pd-top-60">
                  <div className="col-md-4">
                    <div className="section-title">
                      <h4><img src={publicUrl+"assets/img/icons/28.png" }alt="img" />Photos</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    {/* <div className="section-title">
                      <h4>Jason Landville Apartments</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing </p>
                    </div> */}
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="rld-single-input">
                            <input type = "file" name = "image1"  id = "image1" onChange = {this.imageUpload1} />
                        </div>
                        <br />
                        <div className="rld-single-input">
                            <input type = "file" name = "image2"  id = "image2" onChange = {this.imageUpload2} />
                        </div>
                        <br />
                        <div className="rld-single-input">
                            <input type = "file" name = "image3"  id = "image3" onChange = {this.imageUpload3} />
                        </div>
                      </div>

                      <div className="col-12 mt-5">
                        <div className="btn-wrap">
                          <button onClick = {this.handleSubmit}  className="btn btn-yellow">Publish property</button>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        }
}

export default AddNew