import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AgentsSection from './section-components/agent';
import Footer from './global-components/footer';

const Agents = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Login/Signup" />
        <AgentsSection />
        <Footer />
    </div>
}

export default Agents

