import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PostYourRequirement from './section-components/postYourRequirement';
import Footer from './global-components/footer';

const BuildersByCities = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Post Your Requirement"/>
        <PostYourRequirement />
        <Footer />
    </div>
}

export default BuildersByCities

