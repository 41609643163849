import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Page_header extends Component {

    render() {
        let HeaderTitle = this.props.headertitle;
        let publicUrl = process.env.PUBLIC_URL+'/'
        let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle

        let inlineStyle = { backgroundImage: 'url('+publicUrl+'/assets/img/bg/4.png)'}
        if(HeaderTitle == "Property") {
          inlineStyle = { backgroundImage: 'url(/assets/img/otherbg.jpg)'}
        }

        if(HeaderTitle == "Properties by City") {
          inlineStyle = { backgroundImage: 'url(/assets/img/otherbg2.jpg)'}
        }

        if(HeaderTitle == "Recent Propeties"  || HeaderTitle == "Search Properties") {
          inlineStyle = { backgroundImage: 'url(/assets/img/bg2.jpg)'}
        }

        if(HeaderTitle == "Featured Properties") {
          inlineStyle = { backgroundImage: 'url(/assets/img/bg2.jpg)'}
        }

        if(HeaderTitle == "Agents" || HeaderTitle == "Agents by City" || HeaderTitle == "Builders by City") {
          inlineStyle = { backgroundImage: 'url(/assets/img/agent.png)'}
        }

        if(HeaderTitle == "Help Center" || HeaderTitle == "Post Your Requirement") {
          inlineStyle = { backgroundImage: 'url(/assets/img/faqs.jpg)'}
        }

        if(HeaderTitle == "About") {
          inlineStyle = { backgroundImage: 'url(/assets/img/aboutus.jpg)'}
        }

        if(HeaderTitle == "Contact") {
          inlineStyle = { backgroundImage: 'url(/assets/img/contact1.jpeg)'}
        }

        if(HeaderTitle == "Login") {
          inlineStyle = { backgroundImage: 'url(/assets/img/adpost1.jpg)'}
        }

        if(HeaderTitle == "Add Property") {
          inlineStyle = { backgroundImage: 'url(/assets/img/adpost2.jpg)'}
        }

        if(HeaderTitle == "Apartment") {
          inlineStyle = { backgroundImage: 'url(/assets/img/otherbg2.jpg)'}
        }

        if(HeaderTitle == "Property Details") {
          inlineStyle = { backgroundImage: 'url(/assets/img/bg1.jpg)'}
        }

        if(HeaderTitle == "User Dashboard") {
          inlineStyle = { backgroundImage: 'url(/assets/img/user.jpeg)'}
        }
        
        if(HeaderTitle == "Membership") {
          inlineStyle = { backgroundImage: 'url(/assets/img/membership.jpg)'}
        }

        if(HeaderTitle == "Login/Signup") {
          inlineStyle = { backgroundImage: 'url(/assets/img/agents.png)'}
        }

        if(HeaderTitle == "Blogs" || HeaderTitle == "Blog Details") {
          inlineStyle = { backgroundImage: 'url(/assets/img/blog.jpeg)'}
        }

        return (
            <div className="breadcrumb-area jarallax" style= { inlineStyle }>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="breadcrumb-inner">
                      <h1 className="page-title">{ HeaderTitle }</h1>
                      <ul className="page-list">
                        <li><Link to="/">Home</Link></li>
                        <li>{ Subheader }</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
    }
}


export default Page_header