import firebase from '../../firebase';
import React from 'react';
import { Redirect } from 'react-router-dom';

class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            mobileNumber: '',
            userId: '',
            moveToProfile: false
        }
    }

    editProfile(userData) {
        let comp = this

        console.log("UserData", userData)

        comp.setState({
            name: userData.Name,
            email: userData.Email,
            mobileNumber: userData.MobileNumber,
            userId: userData.UserId
        })

        document.getElementById("show").style.display = "none"
        document.getElementById("update").style.display = "block"
    }

    handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value
        })
    }

    back  = () => {
        document.getElementById("show").style.display = "block"
        document.getElementById("update").style.display = "none"
    }

    updateProfile = () => {
        let comp = this
        let data =  this.state

        if(data.name == "") {
            alert("Enter Name")
            document.getElementById("name").focus()
            return
        }

        if(data.email == "") {
            alert("Enter Email")
            document.getElementById("email").focus()
            return
        }

        if(data.mobileNumber == "") {
            alert("Enter Mobile Number")
            document.getElementById("mobileNumber").focus()
            return
        }

        if(data.mobileNumber.length != 13) {
            alert("Enter Correct Mobile Number with country code")
            document.getElementById("mobileNumber").focus()
            return
        }

        firebase.firestore().collection("Users").doc(data.userId).update({
            Name: data.name,
            Email: data.email,
            MobileNumber: data.mobileNumber
        }). then(() => {
            document.getElementById("show").style.display = "block"
            document.getElementById("update").style.display = "none"

            localStorage.setItem("loginName", data.name)

            comp.setState({
                name: '',
                email: '',
                mobileNumber: '',
                userId: '',
                moveToProfile: true
            })
        })
    }

    render() {
        let userData = this.props.userData
        return (
            <div className="rld-main-search">
                {this.state.moveToProfile ? <Redirect to = "/profile/user" /> : null}
                 <div className="row" id = "show">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <h4>Manage Your Name, Number And Email Address.</h4>
                        <p>Below are the name and email addresses on file for your account.</p>

                        <br />

                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2">
                                <h6>Your Name:</h6>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <h6>{userData.Name}</h6>
                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2">
                                <h6>Email Address:</h6>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <h6>{userData.Email}</h6>
                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2">
                                <h6>Mobile Number:</h6>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <h6>{userData.MobileNumber}</h6>
                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-2">
                                <h6>Your Id:</h6>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <h6>{userData.UserId}</h6>
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                        <button className="btn btn-yellow" onClick = {this.editProfile.bind(this, userData)}>EDIT &nbsp; <i className="fa fa-edit" /></button>
                     </div>
                </div>

                <div className="row" id = "update" style = {{display: "none"}}>
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <h4><i className="fa fa-arrow-left" onClick = {this.back} style = {{cursor: "pointer", color: "#FDA94F"}} />&nbsp;Update Profile</h4>
                        <br />

                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <h6>Your Name:</h6>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <input type = "text"  name = "name"  id = "name" onChange = {this.handleChange} value = {this.state.name} style = {{border: "none", width: "100%", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="Name" />
                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <h6>Email Address:</h6>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                            <input type = "text"  name = "email"  id = "email" onChange = {this.handleChange} value = {this.state.email} style = {{border: "none",  width: "100%", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="Email" />
                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4">
                                <h6>Mobile Number: <br /><br /><p>(Add with country code)</p></h6>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                            <br />
                            <input type = "text"  name = "mobileNumber"  id = "mobileNumber" onChange = {this.handleChange} value = {this.state.mobileNumber} style = {{border: "none",  width: "100%", outline: "none", borderBottom: "1px solid #D4D4D4"}} placeholder="Mobile Number Ex: (+911234567890)" />
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                        <button className="btn btn-yellow" onClick = {this.updateProfile}>UPDATE</button>
                     </div>
                </div>
            </div>
        )
    }
}

export default UserProfile

