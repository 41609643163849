import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BuildersByCity from './section-components/builders-by-cities';
import Footer from './global-components/footer';

const BuildersByCities = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Builders by City"/>
        <BuildersByCity />
        <Footer />
    </div>
}

export default BuildersByCities

