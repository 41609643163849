import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import SearchListSection from './section-components/search-list';
import Footer from './global-components/footer';

const SearchList = (props) => {
    var propertyData = []
    var location = ""
    var propertyType = ""
    var propertyFor = ""

    if(props.location.propertyData !== undefined) {
        propertyData = props.location.propertyData
    }

    if(props.location.location !== undefined) {
        location = props.location.location
    }

    if(props.location.propertyType !== undefined) {
        propertyType = props.location.propertyType
    }

    if(props.location.propertyFor !== undefined) {
        propertyFor = props.location.propertyFor
    }
    return <div>
        <Navbar />
        <PageHeader headertitle="Search Properties" 
        // subheader="Apartment rent" 
        />
        <SearchListSection 
            propertyData = {propertyData} 
            location =  {location}
            propertyType = {propertyType}
            propertyFor = {propertyFor}
        />
        <Footer />
    </div>
}

export default SearchList

