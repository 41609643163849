import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import NewsDetailsSection from './blog-components/news-details';
import Footer from './global-components/footer';

const NewsDetails = (props) => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Blog Details" />
        <NewsDetailsSection blogData = {props.location.blogData} />
        <Footer />
    </div>
}

export default NewsDetails

