import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link, Redirect } from 'react-router-dom';

class Explore extends Component {
  state = {
    path: '',
    cityId: '',
    cityName: '',
    moveToCityPage: false
  }

  goToCityPage(key) {
    console.log(key)
    window.scrollTo('top', 0)
    this.setState({
      cityId: key.pushId,
      cityName: key.city,
      moveToCityPage: true
    })
  }


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.explore

    return <div className="explore-area pd-top-85">

      {this.state.moveToCityPage ? <Redirect to = {{
        pathname: "/properties-by-city/" + this.state.cityId, 
        city: {pushId: this.state.cityId, name: this.state.cityName }
      }} /> : null }

      <div className="container">
        <div className="section-title text-center">
          <h2 className="title"> Properties By Cities </h2>
        </div>
        <div className="row">
        { data.items.map( ( item, i )=>
            <div key={ i } className="col-lg-3 col-sm-6">
            <div className="single-explore" style = {{cursor: "pointer"}} onClick = {this.goToCityPage.bind(this, item)}>
              <div className="thumb">
                <img src={ publicUrl+item.image } alt="explore" height = "200" style = {{width: "100%"}} />
                {/* <a href="#"><i className="fa fa-paper-plane" /></a> */}
              </div>
              <div className="details readeal-top">
                <h4 style={{textAlign: "center"}}>{ item.city }</h4>
                <ul className="list">
                  {/* <li>Listings: { item.lisgintnumber }</li> */}
                  {/* <li><i className="fa fa-usd" />Price: ${ item.price }</li> */}
                </ul>
              </div>
            </div>
          </div>
         ) }

        </div>
      </div>
    </div>


        }
}

export default Explore