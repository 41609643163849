import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import FeaturedPropDetails from './section-components/featuredPropertiesDetails';

const FeaturedPropertyDetails = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Featured Properties" />
        <FeaturedPropDetails />
        <Footer />
    </div>
}

export default FeaturedPropertyDetails

