import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Redirect } from 'react-router-dom';
import firebase from '../../firebase';

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}


function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

class Pricing extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userType: '',
        redirectToLogin: false,
        redirectToMembership: false
      }
    }

    handleOnChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }


    buyMembership = (memberShipCategory, amount, memberShipName, days) => {
      let comp = this
      let loginName = localStorage.getItem("loginName")

      var date = new Date();

      let expiresOn = formatDate(addDays(date, days))


      let membershipCo = {
        MemberShipCategory: memberShipCategory,
        Amount: amount,
        AmountInPaise: amount.replace(".", ""),
        MemberShipName: memberShipName,
        Days: days.toString(),
        ExpiresOn: expiresOn,
        MemberShipId: new Date().getTime()
      }

      if(loginName !== null && loginName !== "") {

        let userData = []
        let loginId = localStorage.getItem("loginId")
        console.log(loginId)
        firebase.firestore().collection("Users").doc(loginId)
        .onSnapshot(function(snap) {
          userData = snap.data()

          console.log(userData)
          comp.openRazorPay(membershipCo, userData)
        })

      } else {
        alert("Please Login to continue")
        window.scrollTo('top', 0)
        this.setState({
          redirectToLogin: true
        })
      }
    }

    loadScript = (src) => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    };

    openRazorPay = async (memberShipData, userData) => {
      const cc = this;
      const res = await this.loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      const options = {
        key: "rzp_live_Cs6HieTBVkrhkp",  /** Test API Key: rzp_test_I7fVHVNnJ4dFF6 */
        amount: memberShipData.AmountInPaise,
        currency: "INR",
        name: "Shimoga Properties",
        description: "Membership Transaction",
        image: '',
        order_id: "",

        handler: async function (response) {
          console.log(response)
          let pushId = ''

          firebase.firestore().collection("Membership").add({
            Email: userData.Email,
            UserId: userData.UserId,
            MobileNumber: userData.MobileNumber,
            Name: userData.Name,
            MembershipId: memberShipData.MemberShipId,
            MemberShipCategory: memberShipData.MemberShipCategory,
            Amount: memberShipData.Amount,
            RazorPayId: response.razorpay_payment_id,
            PaymentDate: formatDate(new Date()),
            MemberShipName: memberShipData.MemberShipName,
            MembershipDays: memberShipData.Days + " Days",
            ExpiresOn: memberShipData.ExpiresOn
          })
          .then((docRef) => {
            pushId = docRef.id

            firebase.firestore().collection('Membership').doc(pushId).update({
              PushId: pushId

            }).then(() => {
              window.scrollTo('top', 0)
              cc.setState({
                redirectToMembership: true
              })
            })

          })
          .catch((error) => {
            console.log(error)
          });
        },
        prefill: {
          name: userData.Name,
          email: userData.Email,
          contact: userData.MobileNumber,
        },
        theme: {
          color: "rgb(255,169,69)",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      paymentObject.on("payment.failed", function (response) {
        console.log(response)
        // toast.error("Payment Failed! Please try again.", {
        //   autoClose: 2000,
        // });
      });
    };

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.pricing


    return <div className="user-list-area pd-top-90 pd-bottom-70">
      {this.state.redirectToLogin? <Redirect to = "/registration" /> : null}
      {this.state.redirectToMembership? <Redirect to = "/profile/membership" /> : null}
          <div className="container">
            <div className="section-title text-center">
              <h2 className="title">Owner / Individual</h2>
            </div>

            <div className="row">
              {/* { data.items.map( ( item, i )=> */}
                <div className="col-lg-6 col-sm-6">
                  <div className="single-pricing text-center">
                    <div className="thumb double-img">
                      <img src= "assets/img/icons/26.png" alt= "pricing" />
                    </div>
                    <div className="details">
                      <h4 className="title"> Priority Listing</h4>
                      <h3 className="price">999 Packages</h3>
                      {/* <h6>Every Week</h6> */}
                      <ul>
                        <li>45 Days</li>
                        <li>Ad Posting limit: 2</li>
                        <li>Property lead credit: 5</li>
                        <li>Boosting 15 days</li>
                      </ul>
                      {/* <ul>
                        { item.feature.map( ( featuresitem, i )=>
                          <li key={ i }>{ featuresitem }</li>
                         ) }

                      </ul> */}
                      <br />
                      <button onClick = {() => this.buyMembership('Owner', '999.00', 'Priority', 45)}className="btn btn-yellow">Buy Membership</button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <div className="single-pricing text-center">
                    <div className="thumb double-img">
                      <img src= "assets/img/icons/26.png" alt= "pricing" />
                    </div>
                    <div className="details">
                      <h4 className="title"> Platinum Listing</h4>
                      <h3 className="price">1999 Packages</h3>
                      {/* <h6>Every Week</h6> */}
                      <ul>
                        <li>Premium Tick</li>
                        <li>Ad Posting limit: 5</li>
                        <li>Property lead credit: 12</li>
                        <li>Boosting 15 days Once</li>
                        <li>Featured Ad 5 days</li>
                      </ul>
                      {/* <ul>
                        { item.feature.map( ( featuresitem, i )=>
                          <li key={ i }>{ featuresitem }</li>
                         ) }

                      </ul> */}
                       <button onClick = {() => this.buyMembership('Owner', '1999.00', 'Platinum', 90)}className="btn btn-yellow">Buy Membership</button>
                    </div>
                  </div>
                </div>
                 {/* ) } */}
            </div>
          </div>
          <br />

          <div className="container">
            <div className="section-title text-center">
              <h2 className="title">Dealer / Builder</h2>
            </div>

            <div className="row">
              {/* { data.items.map( ( item, i )=> */}
                <div className="col-lg-4 col-sm-6">
                  <div className="single-pricing text-center">
                    <div className="thumb double-img">
                      <img src= "assets/img/icons/26.png" alt= "pricing" />
                    </div>
                    <div className="details">
                      <h4 className="title"> Star</h4>
                      <h3 className="price">4999 Packages</h3>
                      {/* <h6>Every Week</h6> */}
                      <ul>
                        <li>120 Days</li>
                        <li>Ad Posting limit: 45</li>
                        <li>Buyers Inquiry Unlimited Response</li>
                        <li>10 Ads will be featured</li>
                        <br />
                      </ul>
                      <button onClick = {() => this.buyMembership('Builder', '4999.00', 'Star', 120)}className="btn btn-yellow">Buy Membership</button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="single-pricing text-center">
                    <div className="thumb double-img">
                      <img src= "assets/img/icons/26.png" alt= "pricing" />
                    </div>
                    <div className="details">
                      <h4 className="title">Gold</h4>
                      <h3 className="price">9999 Packages</h3>
                      {/* <h6>Every Week</h6> */}
                      <ul>
                        <li>240 Days</li>
                        <li>Ad Posting limit: 120</li>
                        <li>Buyers Inquiry Unlimited</li>
                        <li>Project Posting: 2</li>
                        <li>20 Ads will be featured</li>
                      </ul>
                      <button onClick = {() => this.buyMembership('Builder', '9999.00', 'Gold', 240)}className="btn btn-yellow">Buy Membership</button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="single-pricing text-center">
                    <div className="thumb double-img">
                      <img src= "assets/img/icons/26.png" alt= "pricing" />
                    </div>
                    <div className="details">
                      <h4 className="title">Diamond</h4>
                      <h3 className="price">14999 Packages</h3>
                      {/* <h6>Every Week</h6> */}
                      <ul>
                        <li>1 year</li>
                        <li>Posting Ad: 500</li>
                        <li>Buyers Inquiry Unlimited Response</li>
                        <li>Project Posting: 5</li>
                        <li>Banner Option</li>
                      </ul>
                      <button onClick = {() => this.buyMembership('Builder', '14999.00', 'Diamond', 365)}className="btn btn-yellow">Buy Membership</button>
                    </div>
                  </div>
                </div>


                <div className="col-lg-6 col-sm-6">
                  <div className="single-pricing text-center">
                    <div className="thumb double-img">
                      <img src= "assets/img/icons/26.png" alt= "pricing" />
                    </div>
                    <div className="details">
                      <h4 className="title">Parternish Program</h4>
                      <h3 className="price">₹ 10,000 </h3>
                      {/* <h6>Every Week</h6> */}
                      <ul>
                        <li>45 Days</li>
                      </ul>
                      <button onClick = {() => this.buyMembership('User', '10000.00', 'Partnership Program', 45)}className="btn btn-yellow">Buy Membership</button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <div className="single-pricing text-center">
                    <div className="thumb double-img">
                      <img src= "assets/img/icons/26.png" alt= "pricing" />
                    </div>
                    <div className="details">
                      <h4 className="title">Banner Advertisments</h4>
                      <h3 className="price">₹ 5,000 </h3>
                      {/* <h6>Every Week</h6> */}
                      <ul>
                        <li>45 Days</li>
                      </ul>
                      <button onClick = {() => this.buyMembership('User', '5000.00', 'Banner Advertisment', 45)}className="btn btn-yellow">Buy Membership</button>
                    </div>
                  </div>
                </div>
                 {/* ) } */}
            </div>
          </div>
        </div>
        }
    }

export default Pricing