import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import UserProfile from '../section-components/userProfile';
import UserAds from './userAds';
import UserMembership from './userMembership';

class UserDetails extends Component {
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.missiontwo

        let activeTab = this.props.activeTab
        let userData = this.props.userData

    return <div className="pd-top-100 pd-bottom-70">
        <div className="container">
        <div className="rld-main-search">
          <div className="row">
              <div className={ "col-md-1 col-sm-1" }>
                    <img src= {"./logo.png"} alt={ imagealt } />
            </div>
            <div className={ "col-md-8 col-sm-8" }>
                  <div className="details">
                    <h4 className="title">{userData.Name}</h4>
                    <p className="author"><i className="fa fa-user" />&nbsp; Profile</p>
                    <p>{userData.MobileNumber + " | " + userData.Email}</p>
                    <p>Your account created on: {userData.JoiningDate}</p>
                </div>
              </div>          
          </div>
          <br />
              <div className="row">
                <div className="col-12">
                  <div className="banner-search-wrap">
                    <ul className="nav nav-tabs rld-banner-tab">

                      <li className="nav-item">
                            {activeTab == "Profile"?
                            <a className="nav-link active" data-toggle="tab" href="#tabs_1">Profile</a> :
                            <a className="nav-link" data-toggle="tab" href="#tabs_1">Profile</a> }
                      </li>

                      <li className="nav-item">
                            {activeTab == "Ads"?
                            <a className="nav-link active" data-toggle="tab" href="#tabs_2">Ads</a> :
                            <a className="nav-link" data-toggle="tab" href="#tabs_2">Ads</a> }
                      </li>

                      <li className="nav-item">
                            {activeTab == "Inbox"?
                            <a className="nav-link active" data-toggle="tab" href="#tabs_3">Inbox</a> :
                            <a className="nav-link" data-toggle="tab" href="#tabs_3">Inbox</a> }
                      </li>

                      <li className="nav-item">
                            {activeTab == "Membership"?
                            <a className="nav-link active" data-toggle="tab" href="#tabs_4">Membership</a> :
                            <a className="nav-link" data-toggle="tab" href="#tabs_4">Membership</a> }
                      </li>
                    </ul>
                    <div className="tab-content">
                    {activeTab == "Profile" ?

                        <div className="tab-pane fade show active" id="tabs_1">
                          <br />
                            <UserProfile userData = {userData} />
                        </div> : 
                        <div className="tab-pane fade" id="tabs_1">
                           <br />
                            <UserProfile userData = {userData} />
                        </div> 
                    }

                    {activeTab == "Ads" ?
                      <div className="tab-pane fade show active" id="tabs_2">
                        {/* <div className="rld-main-search"> */}
                              <UserAds />
                          {/* </div> */}
                      </div> :
                        <div className="tab-pane fade" id="tabs_2">
                          {/* <div className="rld-main-search"> */}
                              <UserAds />
                          {/* </div> */}
                        </div> }

                    {activeTab == "Inbox" ?
                      <div className="tab-pane fade show active" id="tabs_3">
                        <br />
                          <div className="rld-main-search">
                            <div className="row">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                  <h4>Inbox.</h4>
                              </div>
                            </div>
                          </div>
                      </div>:
                      <div className="tab-pane fade" id="tabs_3">
                      <br />
                          <div className="rld-main-search">
                            <div className="row">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                  <h4>Inbox.</h4>
                              </div>
                            </div>
                          </div>
                    </div> }


                    {activeTab == "Membership" ?
                      <div className="tab-pane fade show active" id="tabs_4">
                        <br />
                        <UserMembership />
                      </div> :
                        <div className="tab-pane fade" id="tabs_4">
                          <br />
                          <UserMembership />
                         </div> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>



        }
}

export default UserDetails