import firebase from '../../firebase';
import React from 'react';
import { Redirect } from 'react-router-dom';

class UserMembership extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firebaseData: []
        }
    }

    handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        let comp = this
        let loginId = localStorage.getItem("loginId");

        firebase.firestore().collection("Membership")
        .where("UserId", "==", loginId)
        .onSnapshot(function(snap) {
            let firebaseData = []
            snap.forEach(function(snap){
                firebaseData.push(snap.data())
            })
            comp.setState({
                firebaseData: firebaseData
            })
        })
    }


    render() {
        let userData = this.props.userData
        return (
            <>
                {this.state.firebaseData.map( (rowData, i ) => <>
                    <div className="rld-main-search">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <h4>Membership Name: {rowData.MemberShipName}</h4>
                                <p>Membership Category: {rowData.MemberShipCategory}</p>
                                <p>Membership Amount: {"₹ " + rowData.Amount}</p>
                                <p>Membership Days: {rowData.MembershipDays}</p>
                                <p>Membershp Started Date: {rowData.PaymentDate}</p>
                                <p>Membership Expires On: {rowData.ExpiresOn}</p>
                            </div>
                        </div>  
                    </div>
                    <br />
                    </>
                )}

                {/* <br /> */}

                {/* <div className="rld-main-search">
                 <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <h4>Upgrade your ad to get these benefits just fo <b>₹1000</b> only.</h4>
                        <p>Ad will be Featured for 1 month in the particular area</p>
                        <p>Get more Views</p>
                        <p>Get Verified Badge</p>
                    </div>
                  </div>  
                </div>

                <br />

                <div className = "row">
                    <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                        <button className="btn btn-yellow">BUY MEMBERSHIP</button>
                    </div>
                </div> */}
            </>
        )
    }
}

export default UserMembership

