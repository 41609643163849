import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BuildersSection from './section-components/builder';
import Footer from './global-components/footer';

const Builders = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Login/Signup" />
        <BuildersSection />
        <Footer />
    </div>
}

export default Builders

