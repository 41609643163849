import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import firebase from '../../firebase';
// import { getPriceValue} from './filters';

// var priceValue = getPriceValue()

class SearchList extends Component {
  constructor(props){
    super(props);
    this.state = {
      firebaseData: [],
      location: '',
      propertyFor: '',
      propertyType: '',
      facing: '',
      numberOfFloors: '',
      ownership: '',
      avalability: '',
      furnishedStatus: '',
      businessType: '',
      areaMin: '',
      areaMax: '',
      budgetMin: '',
      budgetMax: ''
    }
  }

  componentDidMount() {
    let comp = this;
    let data = comp.props
    this.setState({
      firebaseData: data.propertyData,
      propertyFor: data.propertyFor,
      propertyType: data.propertyType,
      location: data.location
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  filterProperty = async() => {
    let data = this.state
    let comp = this 

    if(data.propertyFor === '') {
      alert("Select Property For");
      document.getElementById("propertyFor").focus();
      return
    }

    if(data.propertyType === '') {
      alert("Select Property Type");
      document.getElementById("propertyType").focus();
      return
    }

    if(data.propertyType == "Flat" || data.propertyType == "House") {

      let ref = firebase.firestore().collection("Property");
      ref = ref.where("ApprovalStatus", "==", "Approved");
      ref = ref.where("PropertyFor", "==", data.propertyFor);
      ref = ref.where("PropertyType", "==", data.propertyType);

      if (data.budgetMin !== undefined && data.budgetMin !== "" &&
          data.budgetMax !== undefined && data.budgetMax !== "" ) {

          ref = ref.where("Price", ">=", data.budgetMin);
          ref = ref.where("Price", "<=", data.budgetMax);
      }

      if (data.facing !== undefined && data.facing !== "") {
        ref = ref.where("Facing", "==", data.facing);
      }

      if (data.ownership !== undefined && data.ownership !== "") {
        ref = ref.where("Ownership", "==", data.ownership);
      }

      if (data.numberOfFloors !== undefined && data.numberOfFloors !== "") {
        ref = ref.where("NumberOfFloors", "==", data.numberOfFloors);
      }

      if (data.avalability !== undefined && data.avalability !== "") {
        ref = ref.where("Avalability", "==", data.avalability);
      }

      if (data.furnishedStatus !== undefined && data.furnishedStatus !== "") {
        ref = ref.where("FurnishedStatus", "==", data.furnishedStatus);
      }

      if (data.bedrooms !== undefined && data.bedrooms !== "") {
        ref = ref.where("Bedrooms", "==", data.bedrooms);
      }


      await ref
        .get()
        .then((res) => {
          let list = []
          res.docs.forEach((item) => {
            if (data.areaMin !== undefined && data.areaMin !== "" && data.areaMax !== undefined && data.areaMax !== "" ) {
              if(item.data().PropertySize >= data.areaMin && item.data().PropertySize <= data.areaMax) {
                list.push(item.data());
              }
            } else {
              list.push(item.data());
            }
          });

          comp.setState({
            firebaseData: list
          })
        })
        .catch((error) => console.log(error));
    }

    if(data.propertyType == "Shop" || data.propertyType == "Office Spaces") {

      let ref = firebase.firestore().collection("Property");
      ref = ref.where("ApprovalStatus", "==", "Approved");
      ref = ref.where("PropertyFor", "==", data.propertyFor);
      ref = ref.where("PropertyType", "==", data.propertyType);

      if (data.budgetMin !== undefined && data.budgetMin !== "" &&
      data.budgetMax !== undefined && data.budgetMax !== "" ) {

        ref = ref.where("Price", ">=", data.budgetMin);
        ref = ref.where("Price", "<=", data.budgetMax);
      }

      if (data.facing !== undefined && data.facing !== "") {
        ref = ref.where("Facing", "==", data.facing);
      }

      if (data.businessType !== undefined && data.businessType !== "") {
        ref = ref.where("BusinessType", "==", data.businessType);
      }

      if (data.ownership !== undefined && data.ownership !== "") {
        ref = ref.where("Ownership", "==", data.ownership);
      }

      if (data.numberOfFloors !== undefined && data.numberOfFloors !== "") {
        ref = ref.where("NumberOfFloors", "==", data.numberOfFloors);
      }

      if (data.avalability !== undefined && data.avalability !== "") {
        ref = ref.where("Avalability", "==", data.avalability);
      }

      if (data.furnishedStatus !== undefined && data.furnishedStatus !== "") {
        ref = ref.where("FurnishedStatus", "==", data.furnishedStatus);
      }


      await ref
        .get()
        .then((res) => {
          let list = []
          res.docs.forEach((item) => {
            if (data.areaMin !== undefined && data.areaMin !== "" && data.areaMax !== undefined && data.areaMax !== "" ) {
              if(item.data().PropertySize >= data.areaMin && item.data().PropertySize <= data.areaMax) {
                list.push(item.data());
              }
            } else {
              list.push(item.data());
            }
          });

          comp.setState({
            firebaseData: list
          })
        })
        .catch((error) => console.log(error));
    }

    if(data.propertyType == "Plot" || data.propertyType == "Commercial Land") {

      let ref = firebase.firestore().collection("Property");
      ref = ref.where("ApprovalStatus", "==", "Approved");
      ref = ref.where("PropertyFor", "==", data.propertyFor);
      ref = ref.where("PropertyType", "==", data.propertyType);

      if (data.budgetMin !== undefined && data.budgetMin !== "" &&
      data.budgetMax !== undefined && data.budgetMax !== "" ) {

        ref = ref.where("Price", ">=", data.budgetMin);
        ref = ref.where("Price", "<=", data.budgetMax);
      }

      if (data.facing !== undefined && data.facing !== "") {
        ref = ref.where("Facing", "==", data.facing);
      }

      if (data.ownership !== undefined && data.ownership !== "") {
        ref = ref.where("Ownership", "==", data.ownership);
      }

      await ref
        .get()
        .then((res) => {
          let list = []
          res.docs.forEach((item) => {
            if (data.areaMin !== undefined && data.areaMin !== "" && data.areaMax !== undefined && data.areaMax !== "" ) {
              if(item.data().PropertySize >= data.areaMin && item.data().PropertySize <= data.areaMax) {
                list.push(item.data());
              }
            } else {
              list.push(item.data());
            }
          });

          comp.setState({
            firebaseData: list
          })
        })
        .catch((error) => console.log(error));
    }

    if(data.propertyType == "Agricultural Land") {

      let ref = firebase.firestore().collection("Property");
      ref = ref.where("ApprovalStatus", "==", "Approved");
      ref = ref.where("PropertyFor", "==", data.propertyFor);
      ref = ref.where("PropertyType", "==", data.propertyType);

      if (data.budgetMin !== undefined && data.budgetMin !== "" &&
      data.budgetMax !== undefined && data.budgetMax !== "" ) {

        ref = ref.where("Price", ">=", data.budgetMin);
        ref = ref.where("Price", "<=", data.budgetMax);
      }

      if (data.ownership !== undefined && data.ownership !== "") {
        ref = ref.where("Ownership", "==", data.ownership);
      }
      
      await ref
        .get()
        .then((res) => {
          let list = []
          res.docs.forEach((item) => {
            if (data.areaMin !== undefined && data.areaMin !== "" && data.areaMax !== undefined && data.areaMax !== "" ) {
              if(item.data().PropertySize >= data.areaMin && item.data().PropertySize <= data.areaMax) {
                list.push(item.data());
              }
            } else {
              list.push(item.data());
            }
          });

          comp.setState({
            firebaseData: list
          })
        })
        .catch((error) => console.log(error));
    }
  }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.searchlist

        // console.log("Price value", priceValue)


    return <div className="search-page-wrap pd-top-100 pd-bottom-70">
        <div className="search-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 sitebar">
                <h6 className="filter-title mb-4"><img className="mr-3" src={publicUrl+"assets/img/icons/18.png"} alt={ imagealt } />Filter</h6>
                <div className="widget widget-sidebar-search-wrap">
                  <div className="widget-sidebar-search">
                    <div className="title">Filtes</div>
                    <div className="widget-sidebar-item-wrap btn-area">
                      <div className="rld-single-select">
                        <select name = "propertyFor"  id = "propertyFor" onChange = {this.handleChange} value = {this.state.propertyFor} className="select single-select">
                              <option value= "">Property For</option>
                              <option value= "Sell">Buy</option>
                              <option value= "Rent">Rent</option>
                              <option value= "Lease">Lease</option>
                            </select>
                        </div>
                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-input left-icon">
                      <input type="text" value = {this.state.location} placeholder="Entry Landmark Location" />
                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-select">
                      <select className="select single-select" value = {this.state.propertyType} id = "propertyType" name = "propertyType" onChange={this.handleChange}>
                        <option value= "">Property Type</option>
                        <option value= "Flat">Flat</option>
                        <option value= "House">House/Villa</option>
                        <option value= "Plot">Plot/Site</option>
                        <option value= "Shop">Shop/Showroom</option>
                        <option value= "Office Spaces">Office Spaces</option>
                        <option value= "Commercial Land">Commercial Land</option>
                        <option value= "Agricultural Land">Agricultural Land</option>
                      </select>
                    </div>

                    <label style = {{color: "black"}}>Budget</label>
                    <div className= "row">
                      <div className="col-lg-12">
                        <div className="widget-sidebar-item-wrap rld-single-input">
                            <input name = "budgetMin"  id = "budgetMin" onChange = {this.handleChange} value = {this.state.budgetMin}  type = "number" placeholder="Min: Ex: 100000" />
                            <input name = "budgetMax"  id = "budgetMax" onChange = {this.handleChange} value = {this.state.budgetMax}  type = "number" placeholder="Max  Ex: 5000000" />
                          </div>
                      </div>
                    </div>


                    <label style = {{color: "black"}}>Area - Sqft</label>
                    <div className= "row">
                      <div className="col-lg-12">
                        <div className="widget-sidebar-item-wrap rld-single-input">
                           <input name = "areaMin"  id = "areaMin" onChange = {this.handleChange} value = {this.state.areaMin}  type = "number" placeholder="Min: Ex: 1000" />
                           <input name = "areaMax"  id = "areaMax" onChange = {this.handleChange} value = {this.state.areaMax}  type = "number" placeholder="Max  Ex: 8000" />

                        </div>
                      </div>
                    </div>

                    

                    {/* <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                      <div className="title">Budget</div>
                      <div className="price">
                        <span>₹1000.00</span>
                        <span className="float-right">₹100000000.00</span>
                      </div>
                      <div className="rld-price-slider">
                        <div className="ui-slider-handle-price ui-slider-handle" />
                      </div>
                    </div>
                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                      <div className="title">Size</div>
                      <div className="price">
                        <span>600</span>
                        <span className="float-right">6500sqft</span>
                      </div>
                      <div className="rld-size-slider">
                        <div className="ui-slider-handle-size ui-slider-handle" />
                      </div>
                    </div> */}

                    {this.state.propertyType == "Flat" || this.state.propertyType == "House" ?
                      <div className="widget-sidebar-item-wrap rld-single-select">
                        <select  name = "bedrooms"  id = "bedrooms" onChange = {this.handleChange} value = {this.state.bedrooms} className="select single-select">
                            <option value= "">Bedrooms:</option>
                            <option value= "1 RK">1 RK</option>
                            <option value= "1 BHK">1 BHK</option>
                            <option value= "2 BHK">2 BHK</option>
                            <option value= "3 BHK">3 BHK</option>
                            <option value= "4 BHK">4 BHK</option>
                            <option value= "4+ BHK">4+ BHK</option>
                        </select>
                    </div>: null}

                    {this.state.propertyType !== "Agricultural Land" ?
                      <div className="widget-sidebar-item-wrap rld-single-select">
                        <select name = "facing"  id = "facing" onChange = {this.handleChange} value = {this.state.facing}  className="select single-select">
                            <option value= "">Facing:</option>
                            <option value= "North">North</option>
                            <option value= "South">South</option>
                            <option value= "East">East</option>
                            <option value= "West">West</option>
                            <option value= "North-East">North-East</option>
                            <option value= "South-East">South-East</option>
                            <option value= "North-West">North-West</option>
                            <option value= "South-West">South-West</option>
                          </select>
                    </div>: null }

                    {this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?
                       <div className="widget-sidebar-item-wrap rld-single-select">
                          <select name = "businessType"  id = "businessType" onChange = {this.handleChange} value = {this.state.businessType} className="select single-select">
                            <option value= "">Business Type:</option>
                            <option value= "Any">Any</option>
                            <option value= "Clinic">Clinic</option>
                            <option value= "Clothes Shop">Clothes Shop</option>
                            <option value= "Footwear Shop">Footwear Shop</option>
                            <option value= "Grocery Shop">Grocery Shop</option>
                            <option value= "Gym">Gym</option>
                            <option value= "Hotel">Hotel/Restaurant</option>
                            <option value= "Jewellery Shop">Jewellery Shop</option>
                            <option value= "Meat Shop">Meat Shop</option>
                            <option value= "Mobile Shop">Mobile Shop</option>
                            <option value= "Salon">Salon/Spa</option>
                            <option value= "Stationary Shop">Stationary Shop</option>
                          </select>
                        </div>: null }

                      {/* <div className="title d-inline-block float-left mb-0 pt-2">Ownership</div> */}
                      <div className="widget-sidebar-item-wrap rld-single-select">
                          <select name = "ownership"  id = "ownership" onChange = {this.handleChange} value = {this.state.ownership}  className="select single-select">
                            <option value= "">Ownership:</option>
                            <option value= "Owner">Owner</option>
                            <option value= "Agent">Agent</option>
                            <option value= "Builder">Builder/Promoter</option>
                          </select>
                        </div>

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" || this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?
                        <div className="widget-sidebar-item-wrap rld-single-select">
                          <select name = "numberOfFloors"  id = "numberOfFloors" onChange = {this.handleChange} value = {this.state.numberOfFloors} className="select single-select">
                            <option value= "">Number of Floors:</option>
                            <option value= "Ground">Ground</option>
                            <option value= "1">1</option>
                            <option value= "2">2</option>
                            <option value= "3">3</option>
                            <option value= "4">4</option>
                            <option value= "5">5</option>
                            <option value= "6">6</option>
                            <option value= "7">7</option>
                            <option value= "8">8</option>
                            <option value= "9">9</option>
                            <option value= "10">10</option>
                            <option value= "11">11</option>
                            <option value= "12">12</option>
                            <option value= "13">13</option>
                            <option value= "14">14</option>
                            <option value= "15">15</option>
                            <option value= "16">16</option>
                            <option value= "17">17</option>
                            <option value= "18">18</option>
                            <option value= "19">19</option>
                            <option value= "20">20</option>
                            <option value= "21">21</option>
                            <option value= "22">22</option>
                            <option value= "23">23</option>
                            <option value= "24">24</option>
                            <option value= "25">25</option>
                            <option value= "26">26</option>
                            <option value= "27">27</option>
                            <option value= "28">28</option>
                            <option value= "29">29</option>
                            <option value= "30">30</option>
                            <option value= "31">31</option>
                            <option value= "32">32</option>
                            <option value= "33">33</option>
                            <option value= "34">34</option>
                            <option value= "35">35</option>
                            <option value= "36">36</option>
                            <option value= "37">37</option>
                            <option value= "38">38</option>
                            <option value= "39">39</option>
                            <option value= "40">40</option>
                            <option value= "41">41</option>
                            <option value= "42">42</option>
                            <option value= "43">43</option>
                            <option value= "44">44</option>
                            <option value= "45">45</option>
                            <option value= "46">46</option>
                            <option value= "47">47</option>
                            <option value= "48">48</option>
                            <option value= "49">49</option>
                            <option value= "50">50</option>
                          </select>
                      </div> : null }

                      {this.state.propertyType == "Flat" || this.state.propertyType == "House" || this.state.propertyType == "Shop" || this.state.propertyType == "Office Spaces" ?
                        <div className="widget-sidebar-item-wrap rld-single-select">
                          <select name = "furnishedStatus"  id = "furnishedStatus" onChange = {this.handleChange} value = {this.state.furnishedStatus} className="select single-select">
                            <option value= "">Furnished Status::</option>
                            <option value= "Furnished">Furnished</option>
                            <option value= "Semi-Furnished">Semi-Furnished</option>
                            <option value= "Unfurnished">Unfurnished</option>
                          </select>
                      </div> : null }

                      {this.state.propertyType !== "Agricultural Land" && this.state.propertyType !== "Plot" && this.state.propertyType !== "Commercial Land" ?
                        <div className="widget-sidebar-item-wrap rld-single-select">
                          <select name = "avalability"  id = "avalability" onChange = {this.handleChange} value = {this.state.avalability} className="select single-select">
                            <option value= "">Availability:</option>
                            <option value= "Ready to Move">Ready to Move</option>
                            <option value= "Under Construction">Under Construction</option>
                            <option value= "Upcoming">Upcoming</option>
                          </select>
                      </div>:  null }



                    {/* <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                      <div className="title d-inline-block float-left mb-0 pt-2">Construction Status</div>
                      <div className="rld-single-select d-inline-block float-right">
                        <select className="select single-select">
                        <option value= "">Availability:</option>
                            <option value= "Ready to Move">Ready to Move</option>
                            <option value= "Under Construction">Under Construction</option>
                            <option value= "Upcoming">Upcoming</option>
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                      <div className="title d-inline-block float-left mb-0 pt-2">Bathroom</div>
                      <div className="rld-single-select d-inline-block float-right">
                        <select className="select single-select">
                          <option value={1}>2-4</option>
                          <option value={2}>3-4</option>
                          <option value={3}>1-3</option>
                          <option value={3}>2-4</option>
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="widget-sidebar-item-wrap rld-single-select-wrap mb-0">
                      <div className="title d-inline-block float-left mb-0 pt-2">Parking</div>
                      <div className="rld-single-select d-inline-block float-right">
                        <select className="select single-select">
                          <option value={1}>2-4</option>
                          <option value={2}>3-4</option>
                          <option value={3}>1-3</option>
                          <option value={3}>2-4</option>
                        </select>
                      </div>
                    </div> */}
                  </div>
                  <div className="btn-wrap text-center">
                    <button onClick = {this.filterProperty} className="btn btn-yellow"><span className="left"><i className="fa fa-search" /></span>Find Property</button>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8">
                <div className="row mb-3">
                  <div className="col-md-9 col-sm-8">
                    <h6 className="filter-title mt-3 mb-lg-0">Properties Found: {this.state.firebaseData.length} </h6>
                  </div>
                  {/* <div className="col-md-3 col-sm-4">
                    <div className="rld-single-select">
                      <select className="select single-select">
                        <option value={1}>Tile View</option>
                        <option value={2}>Tile View 1</option>
                        <option value={3}>Tile View 2</option>
                        <option value={3}>Tile View 3</option>
                      </select>
                    </div>
                  </div> */}
                </div>

                  { this.state.firebaseData.map( ( item, i )=>
                      <div key={ i } className="single-feature style-two">
                        <div className="thumb">
                           <img src={ item.Image1 } alt="img" />
                        </div>
                        <div className="details">
                          <div className="details-wrap">
                            <a href="#" className="feature-logo">
                             <img src={"/assets/img/icons/l4.png"} alt={ imagealt } />
                            </a>
                           <p className="author"><i className="fa fa-user" /> { item.Name }</p>
                           <h6 className="title readeal-top">{ item.ProjectName }</h6>
                           <h6 className="price">{ "₹ " +item.Price }</h6>
                           <ul className="info-list">
                                <li key={ i } ><i className= "fa fa-bed" /> { item.Bedrooms }</li>
                                <li key={ i } ><i className= "fa fa-bath" /> { item.Bathrooms + " Bath" }</li>
                                <li><img src={"/assets/img/icons/7.png" } alt={ imagealt } /> { item.PropertySize + " Sqft" }</li>
                            </ul>
                            <ul className="contact-list">
                              <li><a className="phone" href="#"><i className="fa fa-phone" /></a></li>
                              <li><a className="message" href="#"><img src={ "/assets/img/icons/8.png" } alt="img" /></a></li>
                              <li className="readeal-top"><Link className="btn btn-yellow" to= {{
                                 pathname: "/property-details/" + item.PropertyId, 
                                 itemData: item 
                              }}>View Details</Link></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

              </div>
            </div>
          </div>
        </div>
      </div>
        }
}

export default SearchList