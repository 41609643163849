import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AgentsByCity from './section-components/agents-by-cities';
import Footer from './global-components/footer';

const AgentsByCities = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Agents by City"/>
        <AgentsByCity />
        <Footer />
    </div>
}

export default AgentsByCities

