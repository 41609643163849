import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import blogdata from '../../data/blogdata.json';
import firebase from '../../firebase';


class PostList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      blogId: '',
      moveToBlogDetails: false,
      blogData: {}
    }
  }

  moveToBlogDetailsPage(key) {
    let comp = this

    console.log(key)
    comp.setState({
      blogId: key.PushId,
      blogData: key,
      moveToBlogDetails: true
    })
  }

  componentDidMount() {
    let comp = this;

    firebase.firestore().collection("Blogs")
    .onSnapshot(function(snap) {
      let newData = []
      snap.forEach(function(snap) {
          newData.push(snap.data());
      })
      comp.setState({
        firebaseData: newData
      })
    })
  }


  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    let data = blogdata.blogs

    return (
      <div>
        {this.state.moveToBlogDetails ? <Redirect to= {{
          pathname: "/blog-details/" + this.state.blogId, 
          blogData: this.state.blogData 
        }} /> : null }
         <div className="property-news-area pd-top-100 pd-bottom-70">
            <div className="container">
              <div className="row">
                { this.state.firebaseData.map( ( item, i )=>
                   <div key={ i } className="col-lg-4" style={{cursor: "pointer"}} onClick={this.moveToBlogDetailsPage.bind(this, item)}>
                    <div className="single-news">
                      <div className="thumb">
                        <img src={item.Image1}  height = "200" width= "400"  alt={ imagealt } />
                      </div>
                      <div className="details">
                        <h4>{ item.Heading }</h4>
                        <p>{ item.SubHeading }</p>
                        {/* <p dangerouslySetInnerHTML={{__html: item.Content.replace(/<li>/gm, "• ").replace(/&nbsp;/gm, '')}}></p> */}
                        <div className="author">
                          {/* <img src={publicUrl+item.authorimage} alt={ imagealt } /> */}
                          <span>By { item.Author }</span>
                          <span className="date">{ item.Date }</span>
                        </div>
                      </div>
                    </div>
                  </div>
                 ) }
                
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default PostList;
