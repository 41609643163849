import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import firebase from '../../firebase';
import GoogleMap from 'google-map-react';
import Geocode from "react-geocode";
// Geocode.setApiKey("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
Geocode.setLanguage("en");

class PropertyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
        firebaseData: [],
        email: '',
        phone: '',
        lat: 13.929930,
        lng: 75.568100
    }
  }

  componentDidMount() {
    let comp = this
    firebase.firestore().collection("Users").doc(comp.props.itemData.UserId)
      .onSnapshot(function(snap) {
          comp.setState({
            phone: snap.data().MobileNumber,
            email: snap.data().Email
          })
      })

      Geocode.fromAddress(comp.props.itemData.Address + "," + comp.props.itemData.CityName).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          // console.log(lat, lng);
          comp.setState({
            lat: lat,
            lng: lng
          })
        },
        (error) => {
          console.error(error);
        }
      );
  }

    render() {

      // console.log("Lat, lng", this.state.lat, this.state.lng)

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

        let itemData = this.props.itemData
        // const itemData = {
        //   Address: "7th Main, 4th Block",
        //   Amenities: "amen1,amen2,amen5,amen6,amen8,amen9,amenB,amenC,amenD,amenF,",
        //   ApprovalStatus: "Approved",
        //   Avalability: "Ready to Move",
        //   Balconies: "2",
        //   Bathrooms: "2",
        //   Bedrooms: "3 BHK",
        //   BookingAmount: "1000",
        //   City: "94UfKaTJwt37ISjtGXZO",
        //   CityName: "Test",
        //   Facing: "East",
        //   FurnishedStatus: "Semi-Furnished",
        //   Image1: "https://firebasestorage.googleapis.com/v0/b/realestate-36757.appspot.com/o/Property%2F%5Bobject%20File%5DSun%20Jun%2026%202022%2021%3A56%3A06%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=a6703c11-4585-42fb-bce5-17cef480d6dd",
        //   Image2: "https://firebasestorage.googleapis.com/v0/b/realestate-36757.appspot.com/o/Property%2F%5Bobject%20File%5DSun%20Jun%2026%202022%2021%3A56%3A12%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=91997853-3dc5-43b2-9248-325e36359617",
        //   Image3: "",
        //   Location: "12.1345, 23.3411",
        //   MaintainanceCharges: "100",
        //   Name: "Vinay",
        //   Ownership: "Owner",
        //   PossessionBy: "test",
        //   PostedDate: "2022-06-26",
        //   Price: "100000",
        //   ProjectArea: "1010",
        //   ProjectDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In bibendum elit magna, ut placerat nunc tempus vel. Donec vitae dictum ligula. Phasellus congue maximus eleifend. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Suspendisse potenti. Suspendisse sollicitudin posuere nunc et vehicula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas aliquam vitae quam at sodales.",
        //   ProjectName: "Testing Property",
        //   PropertyAge: "1 to 3 Years",
        //   PropertyFor: "Sell",
        //   PropertyId: 1656260776006,
        //   PropertyOnTheFloor: "3",
        //   PropertySize: "2000 Sqft",
        //   PropertyType: "Flat",
        //   PushId: "HwrN9SFxEkMgDiBVLuFw",
        //   RegistrationNumber: "2282882",
        //   ResaleProperty: "New Sale",
        //   Status: "Active",
        //   Url: "/property-details",
        //   UserId: "SP2"
        // }
        // console.log("ItemData", itemData)


    return <div className="property-details-area">
      <div className="bg-gray pd-top-100 pd-bottom-90">
        <div className="container">
          <div className="row ">
            <div className="col-xl-9 col-lg-8">
              <div className="property-details-slider">
              <div className="item">
                  <div className="thumb">
                    <img src={ itemData.Image1 } height = "700" alt={ imagealt } />
                  </div>
                </div>
                <div className="item">
                  <div className="thumb">
                    <img src={ itemData.Image2} height = "700" alt={ imagealt }/>
                  </div>
                </div>
                <div className="item">
                  <div className="thumb">
                    <img src={ itemData.Image3} height = "700" alt={ imagealt }/>
                  </div>
                </div>

              </div>
              <div className="property-details-slider-info">
                <h3><span>{"₹ " +itemData.Price}</span>{itemData.ProjectName}</h3>
                <h6>Registration No: {itemData.RegistrationNumber}</h6>
                {/* <del>$500</del> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                <div className="owner-info text-center">
                  <div className="thumb">
                    <img src={ "/assets/img/news/21.png"} alt={ imagealt } />
                  </div>
                  <div className="details">
                    <h6>{itemData.Name}</h6>
                    <span className="designation">Owner</span>
                    <p className="reviews"><i className="fa fa-star" /><span>4.8</span> </p>
                  </div>
                </div>
                <div className="contact">
                  <h6>Contact Us</h6>
                  <div className="rld-single-input">
                    <input type="text" placeholder="Full Name" />
                  </div>
                  <div className="rld-single-input">
                    <input type="text" placeholder="Email" />
                  </div>
                  <div className="rld-single-input">
                    <input type="text" placeholder="Messages" />
                  </div>
                  <a className="btn btn-yellow" href="#">Send Messages</a>
                </div>
                <div className="contact-info">
                  <h6 className="mb-3">Contact Info</h6>
                  <div className="media">
                    {/* <div className="media-left">
                      <img src={"/assets/img/icons/1.png"} alt={ imagealt } />
                    </div>
                    <div className="media-body">
                      <p>Address</p>
                      <span>Long Island, NY 11355, USA</span>
                    </div> */}
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="media-body">
                      <p>Phone</p>
                      <span>{this.state.phone}</span>
                    </div>
                  </div>
                  <div className="media mb-0">
                    <div className="media-left">
                      <i className="fa fa-envelope" />
                    </div>
                    <div className="media-body">
                      <p>Email</p>
                      <span>{this.state.email}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pd-top-90">
          <div className="col-lg-9">
            <div className="property-info mb-5">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Bedrooms</h5>
                    <p><i className="fa fa-bed" />{itemData.Bedrooms}</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Bathrooms</h5>
                    <p><i className="fa fa-bath" />{itemData.Bathrooms}</p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Area</h5>
                    <p><img src={"/assets/img/icons/7.png"}alt={ imagealt } />{itemData.PropertySize + " Sqft"}</p>
                  </div>
                </div>
                {/* <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Parking</h5>
                    <p><i className="fa fa-car" />01 Indoor</p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="property-news-single-card style-two border-bottom-yellow">
              {/* <h4>Base Floor Plan</h4> */}
              <p>{itemData.ProjectDescription}</p>
              {/* <a href="#">Read More</a> */}
            </div>
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Address: {itemData.Address + "," + itemData.CityName}</h4>
              <GoogleMap
                  bootstrapURLKeys={{
                  	key: "AIzaSyCnaq_nQX-2yYsEQHJifUmS2q3Je5N-f7c"
                  }}
                  defaultZoom={5}
                  defaultCenter = { { lat: this.state.lat, lng: this.state.lng }}
                  // options={{
                  // 	styles: props.data.styles
                  // }}
                >
			      </GoogleMap>
            </div>
            <div className="property-news-single-card border-bottom-yellow">
              <h4>Amenities</h4>
              <div className="row">
                <div className="col-sm-4">
                  <ul className="rld-list-style mb-3 mb-sm-0">
                    {itemData.Amenities.includes("amen1") ? <li><i className="fa fa-check" /> Car Parking</li>: null }
                    {itemData.Amenities.includes("amen2") ? <li><i className="fa fa-check" /> Security Services</li>: null }
                    {itemData.Amenities.includes("amen3") ? <li><i className="fa fa-check" /> Water Supply</li>: null }
                    {itemData.Amenities.includes("amen4") ? <li><i className="fa fa-check" /> Elevators</li>: null }
                    {itemData.Amenities.includes("amen5") ? <li><i className="fa fa-check" /> Power backup</li>: null }
                    {itemData.Amenities.includes("amen6") ? <li><i className="fa fa-check" /> 24 hour Security</li>: null }
                    {itemData.Amenities.includes("amen7") ? <li><i className="fa fa-check" /> Walking Track</li>: null }
                  </ul>
                </div>
                
                <div className="col-sm-4">
                  <ul className="rld-list-style mb-3 mb-sm-0">
                    {itemData.Amenities.includes("amen8") ? <li><i className="fa fa-check" /> Play Area</li>: null }
                    {itemData.Amenities.includes("amen9") ? <li><i className="fa fa-check" /> Club House</li>: null }
                    {itemData.Amenities.includes("amenA") ? <li><i className="fa fa-check" /> Swimming Pool</li>: null }
                    {itemData.Amenities.includes("amenB") ? <li><i className="fa fa-check" /> Gym</li>: null }
                    {itemData.Amenities.includes("amenC") ? <li><i className="fa fa-check" /> Rooftop Garden</li>: null }
                    {itemData.Amenities.includes("amenD") ? <li><i className="fa fa-check" /> Open Deck</li>: null }
                    {itemData.Amenities.includes("amenE") ? <li><i className="fa fa-check" /> Sky lounge</li>: null }
                  </ul>
                </div>
                <div className="col-sm-4">
                  <ul className="rld-list-style mb-3 mb-sm-0">
                    {itemData.Amenities.includes("amenF") ? <li><i className="fa fa-check" /> Spa and Saloon</li>: null }
                    {itemData.Amenities.includes("amenG") ? <li><i className="fa fa-check" /> Concierge Services</li>: null }
                    {itemData.Amenities.includes("amenH") ? <li><i className="fa fa-check" /> Restaurants</li>: null }
                    {itemData.Amenities.includes("amenI") ? <li><i className="fa fa-check" /> Party hall</li>: null }
                    {itemData.Amenities.includes("amenJ") ? <li><i className="fa fa-check" /> Temple and Religious activity place</li>: null }
                    {itemData.Amenities.includes("amenK") ? <li><i className="fa fa-check" /> Cinema Hall</li>: null }
                    {itemData.Amenities.includes("amenL") ? <li><i className="fa fa-check" /> Wifi Connectivity</li>: null }
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="property-news-single-card border-bottom-yellow">
              <h4>Floor Plan</h4>
              <div className="thumb">
                <img src={ publicUrl+"/assets/img/others/10.png"} alt={imagealt} />
              </div>
            </div> */}
            <div className="property-news-single-card border-bottom-yellow pb-3">
              <h4>Facts and Features</h4>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-bed" />
                    </div>
                    <div className="media-body">
                      <h6>Living Room</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-car" />
                    </div>
                    <div className="media-body">
                      <h6>Garage</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={"/assets/img/icons/7.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <h6>Dining Area</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-bed" />
                    </div>
                    <div className="media-body">
                      <h6>Bedroom</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-bath" />
                    </div>
                    <div className="media-body">
                      <h6>Bathroom</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                       <img src={"/assets/img/icons/17.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <h6>Gym Area</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="property-news-single-card border-bottom-yellow pb-3">
              <h4>Other Features</h4>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={"/assets/img/icons/7.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <h6>Availability</h6>
                      <p>{itemData.Avalability}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={"/assets/img/icons/7.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <h6>Facing</h6>
                      <p>{itemData.Facing}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={"/assets/img/icons/7.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <h6>Furnished Status</h6>
                      <p>{itemData.FurnishedStatus}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={"/assets/img/icons/7.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <h6>Property Age</h6>
                      <p>{itemData.PropertyAge}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={"/assets/img/icons/7.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <h6>Booking Amount</h6>
                      <p>{"₹ " + itemData.BookingAmount}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={"/assets/img/icons/7.png"} alt={imagealt} />
                    </div>
                    <div className="media-body">
                      <h6>Maintainance Charges</h6>
                      <p>{"₹ " + itemData.MaintainanceCharges}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  

            {/* <div className="property-news-single-card border-bottom-yellow mb-0">
              <h4>3D Gallery</h4>
              <div className="thumb">
                <img src={ publicUrl+"/assets/img/others/11.png"}  alt={imagealt} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>




        }
}

export default PropertyDetails