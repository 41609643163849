import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import firebase from '../../firebase';

class Property extends Component {
  constructor(props) {
    super(props);
    this.state = {
        firebaseData: [],
        locations: [],
        cityName: '',
        city: '',
    }
  }

  componentDidMount() {
    let comp = this;

    firebase.firestore().collection("Location")
    .onSnapshot(function(querySnapshot) {
        var cities = []
        querySnapshot.forEach(function(doc) {
          cities.push(doc.data())
      })
      comp.setState({
        locations: cities
      })
    })

    console.log(this.props.cityId)

    if(this.props.cityId !== "") {
      firebase.firestore().collection("Property")
      .where("ApprovalStatus", "==", "Approved")
      .where("City", "==", this.props.cityId)
      .onSnapshot(function(querySnapshot) {
          var properties = []
          querySnapshot.forEach(function(doc) {
            properties.push(doc.data())
        })
        comp.setState({
          firebaseData: properties,
          city: comp.props.cityId,
          cityName: comp.props.cityName
        })
      })
    } else {
      firebase.firestore().collection("Property")
      .where("ApprovalStatus", "==", "Approved")
      .limit(4)
      .onSnapshot(function(querySnapshot) {
          var properties = []
          querySnapshot.forEach(function(doc) {
            properties.push(doc.data())
        })
        comp.setState({
          firebaseData: properties
        })
      })
    }
  }

  cityOnChange = (e) => {
    var cityPushId = e.target.value;
    let comp =  this

    if(cityPushId !== "") {
      firebase.firestore().collection("Location").doc(cityPushId)
      .onSnapshot(function(snap) {
          comp.setState({
            city: cityPushId,
            cityName: snap.data().Name,
          })
      })

      firebase.firestore().collection("Property")
      .where("City", "==", cityPushId)
      .where("ApprovalStatus", "==", "Approved")
      .onSnapshot(function(querySnapshot) {
          var properties = []
          querySnapshot.forEach(function(doc) {
            properties.push(doc.data())
        })
        comp.setState({
          firebaseData: properties
        })
      })
    } else {
      comp.setState({
        city: '',
        cityName: '',
        firebaseData: []
      })
    }
  }


    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.propertybtcities


    return <div className="properties-area pd-top-92">
        <div className="container">
          <div className="row">
                <div className="col-lg-4 mb-3">
                  <div className="rld-single-select">
                    <select name = "city"  id = "city" onChange = {this.cityOnChange} value = {this.state.city} className="select single-select">
                      <option value= "">Select City</option>
                      {this.state.locations.map((item, key) => (
                         <option value={item.PushId}>{item.Name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            <div className="section-title">
            <h2 className="title">{ this.state.cityName }</h2>
            </div>
            {this.state.firebaseData.length == 0 ? <>
              <h5 className="title">No Result Found</h5> 
              <br /> <br /> 
              </>: null }
            <div className="row">
                {this.state.firebaseData.map( ( item, i )=>
                      <div key={ i } className={"rld-filter-item  col-lg-3 col-sm-6 "+item.cat}>
                        <div className="single-feature">
                          <div className="thumb">
                             <img src={ item.Image1 } height = "250" alt="img" />
                          </div>
                          <div className="details">
                            <a href="#" className="feature-logo">
                               <img src={"/assets/img/icons/l4.png" } alt={ imagealt } />
                            </a>
                            <p className="author"><i className="fa fa-user" /> { item.Name }</p>
                            <h6 className="title readeal-top">{ item.ProjectName }</h6>
                            <h6 className="price">{ "₹ " +item.Price }</h6>
                            <p>{ item.CityName }</p>
                            <ul className="info-list">
                                <li key={ i } ><i className= "fa fa-bed" /> { item.Bedrooms }</li>
                                <li key={ i } ><i className= "fa fa-bath" /> { item.Bathrooms + " Bath" }</li>
                              <li><img src={"/assets/img/icons/7.png" } alt={ imagealt } /> { item.PropertySize + " Sqft" }</li>
                            </ul>
                            <ul className="contact-list">
                              <li><a className="phone" href="#"><i className="fa fa-phone" /></a></li>
                              <li><a className="message" href="#"><img src={ "/assets/img/icons/8.png" } alt="img" /></a></li>
                              <li className="readeal-top"><Link className="btn btn-yellow" to= {{
                                 pathname: "/property-details/" + item.PropertyId, 
                                 itemData: item 
                              }}>View Details</Link></li>
                            </ul>
                          </div>
                        </div>
                      </div>
              )}
          </div>
        </div>
      </div>


        }
}

export default Property