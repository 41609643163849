import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyDetailsSection from './section-components/property-details';
import RecomandProperties from './section-components/recomand-properties';
import Footer from './global-components/footer';
import { useParams } from 'react-router-dom';

const PropertyDetails = (props) => {
    console.log(props.location.itemData )
    return <div>
        <Navbar />
        <PageHeader headertitle="Property Details" />
        <PropertyDetailsSection itemData = {props.location.itemData} />
        <RecomandProperties userId = {props.location.itemData.UserId} city = {props.location.itemData.City}/>
        <Footer />
    </div>
}

export default PropertyDetails

