import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import axios from "axios";
import { Redirect } from 'react-router-dom';
import firebase from '../../firebase';
import { data } from 'jquery';

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

class Registration extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loginEnable: true,
        signUpEnable: false,
        verifyOtpEnable: false,
        mobileNumber: '',
        otp: '',
        verificationId: '',
        redirection: false,


        registrationEnable: false,
        userName: '',
        email: '',
        accountMobileNumber: '',
        accountOtp: '',
        accountVerifyOtpEnable: false,
        mobileNumbers: [],
        loginName: '',
        loginId: ''
      }
    }

    componentDidMount() {
      let comp = this;

      firebase.firestore().collection("Users")
      .onSnapshot(function(querySnapshot) {
          var mobileNumbers = []
          querySnapshot.forEach(function(doc) {
            mobileNumbers.push(doc.data().MobileNumber.slice(3))
        })
        comp.setState({
          mobileNumbers: mobileNumbers
        })
      })
    }

    enableSignUp = (e) => {
      this.setState({
        signUpEnable: true,
        loginEnable: false
      })
    }

    enableLogin = (e) => {
      this.setState({
        signUpEnable: false,
        loginEnable: true
      })
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }

    sendOTP = async (e) => {
      let data = this.state
      let comp =  this

      if(data.mobileNumber == '') {
        alert("Enter Mobile Number")
        document.getElementById("mobileNumber").focus()
        return
      }

      if(data.mobileNumber.length != 10) {
        alert("Enter Correct Mobile Number")
        document.getElementById("mobileNumber").focus()
        return
      }

      var loginName = ""
      var loginId = ""

    const userRef = firebase.firestore().collection("Users").where("MobileNumber", "==", "+91" + data.mobileNumber);
    const querySnapshot = await userRef.get();
    if (querySnapshot.docs.length > 0) {
      querySnapshot.docs.forEach(async function (doc) {
          console.log(doc.data())

          loginName = doc.data().Name
          loginId = doc.data().UserId

      var url =
        "https://2factor.in/API/V1/a1382fa0-a0c1-11eb-80ea-0200cd936042/SMS/+91" +
        data.mobileNumber +
        "/AUTOGEN";

        await axios.post(url).then((response) => {
            console.log(response.data)

            if(response.data.Status == 'Success') {
                comp.setState({
                  verificationId: response.data.Details,
                  loginEnable: true,
                  verifyOtpEnable: true,
                  loginId: loginId,
                  loginName: loginName
                })
            } else {
              alert("Unable to proceed further. Please try after sometime")
            }
        })
        .catch(function (error) {
          console.log(error.response)
          if (error.response) {
            alert(error.response.data.Details)
          }
        });
      })

      } else {
        alert("Mobile Number doesn't exist. Please SignUp")
      }
    }

    verifyOTP = async (e) => {
      let data = this.state
      let comp =  this

      let app = this.props

      if(data.otp == '') {
        alert("Enter OTP")
        document.getElementById("otp").focus()
        return
      }

      var url =
        "https://2factor.in/API/V1/a1382fa0-a0c1-11eb-80ea-0200cd936042/SMS/VERIFY/" +
        data.verificationId +
        "/" +
        data.otp;
        
      await axios.post(url).then((response) => {
        console.log(response);
        if(response.data.Status == 'Success') {

          localStorage.setItem("loginName", data.loginName)
          localStorage.setItem("loginId", data.loginId)
  
          comp.setState({
            redirection: true
          })
        } else {
          alert("Unable to proceed further. Please try after sometime")
        }
      })
      .catch(function (error) {
        console.log(error.response)
        if (error.response) {
          alert(error.response.data.Details)
        }
      });
    }


    accountSendOTP = async (e) => {
      let data = this.state
      let comp =  this

      if(data.userName == '') {
        alert("Enter Name")
        document.getElementById("userName").focus()
        return
      }

      if(data.email == '') {
        alert("Enter Email")
        document.getElementById("email").focus()
        return
      }

      if(data.accountMobileNumber == '') {
        alert("Enter Mobile Number")
        document.getElementById("accountMobileNumber").focus()
        return
      }

      if(data.accountMobileNumber.length != 10) {
        alert("Enter Correct Mobile Number")
        document.getElementById("accountMobileNumber").focus()
        return
      }

      if(data.mobileNumbers.indexOf(data.accountMobileNumber) !== -1) {
        alert("Mobile Number Already Exists. Please Login!!")
        return
      }

      var url =
        "https://2factor.in/API/V1/a1382fa0-a0c1-11eb-80ea-0200cd936042/SMS/+91" +
        data.accountMobileNumber +
        "/AUTOGEN";

        await axios.post(url).then((response) => {
            console.log(response.data)

            if(response.data.Status == 'Success') {
                comp.setState({
                  verificationId: response.data.Details,
                  signUpEnable: true,
                  accountVerifyOtpEnable: true,
                })
            } else {
              alert("Unable to proceed further. Please try after sometime")
            }
        })
        .catch(function (error) {
          console.log(error.response)
          if (error.response) {
            alert(error.response.data.Details)
          }
        });
    }

    accountVerifyOTP = async (e) => {
      let data = this.state
      let comp =  this

      let app = this.props

      if(data.accountOtp == '') {
        alert("Enter OTP")
        document.getElementById("accountOtp").focus()
        return
      }

      var url =
        "https://2factor.in/API/V1/a1382fa0-a0c1-11eb-80ea-0200cd936042/SMS/VERIFY/" +
        data.verificationId +
        "/" +
        data.accountOtp;
        
      await axios.post(url).then((response) => {
        console.log(response);
        if(response.data.Status == 'Success') {
          comp.setState({
            accountVerifyOtpEnable: false,
            registrationEnable: true
          })

          document.getElementById("getOtp").style.display = "none"
          document.getElementById("register").style.display = "block"

        } else {
          alert("Unable to proceed further. Please try after sometime")
        }
      })
      .catch(function (error) {
        console.log(error.response)
        if (error.response) {
          alert(error.response.data.Details)
        }
      });
    }

    registerUser = async (e) => {
      let comp = this;
      let data1 = this.state

      var today = new Date()

        const uniqueId = (
          await firebase.firestore().collection("UniqueId").doc("Users").get()
        ).data();
        const nextId = "SP" + (uniqueId.Id + 1);

        console.log("nextid", nextId)

        await firebase.firestore().collection("Users").doc(nextId).set({
            Email: data1.email,
            JoiningDate: formatDate(today),
            MobileNumber: "+91" + data1.accountMobileNumber,
            Name: data1.userName,
            Orders: 0,
            PP: "",
            Wallet: 0,
            Status: "Active",
            ApprovalStatus: "Pending",
            UserId: nextId
          })
          .then(() => {
            firebase.firestore().collection("UniqueId").doc("Users").update({
                Id: firebase.firestore.FieldValue.increment(1),
              });

              localStorage.setItem("loginName", data1.userName)
              localStorage.setItem("loginId", nextId)

            // alert("Registration Was Successfull!!")
            comp.setState({
              redirection: true
            })
          })
          .catch((error) => {
            console.log(error)
            alert("Unabel to do registration. Please try after Sometime!!")
          });
    }


    render() {
        console.log("Mobile Numbers: ", this.state.mobileNumbers)
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.whychooseus



    return <div className="register-page-area pd-bottom-100">
       { this.state.redirection ? (<Redirect push to="/"/>) : null }
          <div className="container">
            <div className="row justify-content-center">

              {this.state.loginEnable ?
              <div className="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
                <div className="contact-form-wrap contact-form-bg">
                  <h4>Login</h4>
                  <div className="rld-single-input">
                    <input type="number" onChange = {this.handleChange} name = "mobileNumber" id = "mobileNumber" placeholder="Your Mobile Number" />
                  </div>

                  {!this.state.verifyOtpEnable ?
                  <div className="btn-wrap">
                    <button onClick = {this.sendOTP} className="btn btn-yellow">GET OTP</button>
                  </div>

                  : <>

                  <div className="rld-single-input">
                    <input type="number" onChange = {this.handleChange} name = "otp" id = "otp" placeholder="OTP" />
                  </div>

                  <div className="btn-wrap">
                    <button onClick = {this.verifyOTP}  className="btn btn-yellow">VERIFY OTP</button>
                  </div>

                  </> }

                  <br />
                  <p>New to Shimoga  Properties? <a onClick = {this.enableSignUp} style = {{color: "#FDA94F", cursor: "pointer"}}>SignUp</a></p>

                </div>
              </div> : null }

              {this.state.signUpEnable ?


              <div className="col-xl-4 col-lg-5 col-md-6">
                <div className="contact-form-wrap contact-form-bg">
                  <h4>Create Your Account</h4>
                  <div className="rld-single-input">
                    <input type="text" onChange = {this.handleChange} name = "userName" id = "userName" placeholder="Your Name" />
                  </div>
                  <div className="rld-single-input">
                    <input type="text" onChange = {this.handleChange} name = "email" id = "email" placeholder="Your Email" />
                  </div>
                  <div className="rld-single-input">
                    <input type="number" onChange = {this.handleChange} name = "accountMobileNumber" id = "accountMobileNumber" placeholder="Your Mobile Number" />
                  </div>

                  {!this.state.accountVerifyOtpEnable ?
                  <div className="btn-wrap">
                    <button id = "getOtp"onClick = {this.accountSendOTP} className="btn btn-yellow">GET OTP</button>
                    <button id = "register" style = {{display: "none" }} onClick = {this.registerUser} className="btn btn-yellow">REGISTER</button>
                  </div>

                  : <>

                  <div className="rld-single-input">
                    <input type="number" onChange = {this.handleChange} name = "accountOtp" id = "accountOtp" placeholder="OTP" />
                  </div>

                  <div className="btn-wrap">
                    <button onClick = {this.accountVerifyOTP}  className="btn btn-yellow">VERIFY OTP</button>
                  </div>

                  </> }



                  {/* <ul className="social-icon">
                    <li className="ml-0">
                      <a href="#" target="_blank"><i className="fa fa-facebook  " /></a>
                    </li>
                    <li>
                      <a href="#" target="_blank"><i className="fa fa-twitter  " /></a>
                    </li>
                    <li>
                      <a href="#" target="_blank"><i className="fa fa-linkedin" /></a>
                    </li>
                  </ul> */}

                  <br />
                  <p>Already Registerd? <a onClick = {this.enableLogin} style = {{color: "#FDA94F", cursor: "pointer"}}>Login</a> Here</p>
                </div>
              </div> : null }
            </div>
          </div>
        </div>
        }
}

export default Registration