import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import firebase from '../../firebase';

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

class PostYourRequirement extends Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        mobileNumber: '',
        email: '',
        message: '',
      }
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }

    pushData = async (e) => {
      let data = this.state;
      let comp = this;


      if(data.name === "") {
        alert("Enter Name");
        document.getElementById("name").focus();
        return;
      }

      if(data.mobileNumber === "") {
        alert("Enter Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
      }

      
      if(data.mobileNumber.length !== 10) {
        alert("Enter Correct Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
      }
      
      if(data.email === "") {
        alert("Enter Email");
        document.getElementById("email").focus();
        return;
      }

      if(data.message === "") {
        alert("Enter Your Requirement");
        document.getElementById("message").focus();
        return;
      }

      var today = new Date()

      let pushid = ''

        await firebase.firestore().collection("UserRequirement").add({
            JoiningDate: formatDate(today),
            Name: data.name,
            Email: data.email,
            MobileNumber: "+91" + data.mobileNumber,
            Message: data.message,
            Status: "Active",
          })
          .then((docRef) => {
            pushid = docRef.id
      
            firebase.firestore().collection('UserRequirement').doc(pushid).update({
              PushId: pushid
            })

            alert("Thank You. Our Team will get back to you shortly!!")
            comp.setState({
                name: '',
                mobileNumber: '',
                email: '',
                message: '',
            })
          })
          .catch((error) => {
            console.log(error)
            alert("Unable to post. Please try after Sometime!!")
          });
    }


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.whychooseus


    return <div className="register-page-area pd-bottom-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="contact-form-wrap contact-form-bg">
                  <h4>Post Your Requirement</h4> <br />

                  <div className="rld-single-input">
                    <input type="text" name = "name" id = "name" onChange={this.handleChange} value = {this.state.name} placeholder="Name" />
                  </div>
                  <div className="rld-single-input">
                    <input type="text"  name = "mobileNumber" id = "mobileNumber" onChange={this.handleChange} value = {this.state.mobileNumber} placeholder="Mobile Number" />
                  </div>
                  <div className="rld-single-input">
                    <input type="text"  name = "email" id = "email" onChange={this.handleChange} value = {this.state.email} placeholder="Email" />
                  </div>

                  <div className="rld-single-input">
                  <textarea name = "message"  id = "message" onChange = {this.handleChange} value = {this.state.message}  rows={10} placeholder="Your Requirement..."/>
                  </div>
                  <div className="btn-wrap">
                    <button onClick={this.pushData} className="btn btn-yellow">Submit</button>
                  </div>

                  {/* <ul className="social-icon">
                    <li className="ml-0">
                      <a href="#" target="_blank"><i className="fa fa-facebook  " /></a>
                    </li>
                    <li>
                      <a href="#" target="_blank"><i className="fa fa-twitter  " /></a>
                    </li>
                    <li>
                      <a href="#" target="_blank"><i className="fa fa-linkedin" /></a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        }
}

export default PostYourRequirement