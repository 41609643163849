import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import firebase from '../../firebase';

class BuildersByCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
        firebaseData: [],
        locations: [],
    }
  }

  componentDidMount() {
    let comp = this;

    firebase.firestore().collection("Location")
    .onSnapshot(function(querySnapshot) {
        var cities = []
        querySnapshot.forEach(function(doc) {
          cities.push(doc.data())
      })
      comp.setState({
        locations: cities
      })
    })
  }

  cityOnChange = (e) => {
    var cityPushId = e.target.value;
    let comp =  this

    if(cityPushId !== "") {
      firebase.firestore().collection("Location").doc(cityPushId)
      .onSnapshot(function(snap) {
          comp.setState({
            city: cityPushId,
            cityName: snap.data().Name,
          })
      })

      firebase.firestore().collection("Agents")
      .where("CityId", "==", cityPushId)
      .where("ApprovalStatus", "==", "Approved")
      .where("Type", "==", "Builder")
      .onSnapshot(function(querySnapshot) {
          var properties = []
          querySnapshot.forEach(function(doc) {
            properties.push(doc.data())
        })
        comp.setState({
          firebaseData: properties
        })
      })
    } else {
      comp.setState({
        city: '',
        cityName: '',
        firebaseData: []
      })
    }
  }


    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.propertybtcities


    return <div className="properties-area pd-top-92">
        <div className="container">
          <div className="row">
                <div className="col-lg-4 mb-3">
                  <div className="rld-single-select">
                    <select name = "city"  id = "city" onChange = {this.cityOnChange} value = {this.state.city} className="select single-select">
                      <option value= "">Select City</option>
                      {this.state.locations.map((item, key) => (
                         <option value={item.PushId}>{item.Name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            <div className="section-title">
            {this.state.cityName !== undefined || this.state.cityName !== "" ?   
            <h2 className="title">Builders</h2> :
            <h2 className="title">{ this.state.cityName + " Builders" }</h2> }
            </div>
            {this.state.firebaseData.length == 0 ? <>
              <h5 className="title">No Result Found</h5> 
              <br /> <br /> 
              </>: null }
            <div className="row">
                {this.state.firebaseData.map( ( item, i )=>
                      <div key={ i } className={"rld-filter-item  col-lg-3 col-sm-6 "+item.cat}>
                        <div className="single-feature">
                          <div className="thumb">
                             <img src= "/assets/img/user.png" height = "100" alt="img" />
                          </div>
                          <div className="details">
                            <a href="#" className="feature-logo">
                               <img src={"/assets/img/icons/l4.png" } alt={ imagealt } />
                            </a>
                            <h6 className="title"><i className="fa fa-user" /> { item.Name }</h6>
                            <p className="title readeal-top"><i className="fa fa-industry" />&nbsp; { item.CompanyName }</p>
                            <h6 className="price"><i className="fa fa-phone" />&nbsp; {item.MobileNumber }</h6>
                          </div>
                        </div>
                      </div>
              )}
          </div>
        </div>
      </div>


        }
}

export default BuildersByCity