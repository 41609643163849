import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import firebase from '../../firebase';
import { Redirect } from 'react-router-dom';

class Banner extends Component {
    constructor(props) {
      super(props);
      this.state = {
        location1: '',
        propertyType1: '',
        location2: '',
        propertyType2: '',
        location3: '',
        propertyType3: '',
        res1Lat: '',
        res1Long: '',
        res2Lat: '',
        res2Long: '',
        res3Lat: '',
        res3Long: '',
        buyData: [],
        rentData: [],
        leaseData: [],
        moveToSearchPage1: false,
        moveToSearchPage2: false,
        moveToSearchPage3: false
      }
    }
    componentDidMount() {

    const $ = window.$;
    
    //  if ($('.single-select').length){
    //         $('.single-select').niceSelect();
    //     }
  }

  handleChnage = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSelect1 = (address) => {
    let comp = this
    geocodeByAddress(address)
      .then(function(results){
          var add = results[0].formatted_address
        comp.setState({
            location1: add
        })
      })
      geocodeByAddress(address)
      .then(results1 => getLatLng(results1[0]))
      .then(({ lat, lng }) => {
        // console.log('Successfully got latitude and longitude', { lat, lng })
        comp.setState({
            res1Lat: lat,
            res1Long: lng
        })
      }
        
      )
      .catch(error => console.error('Error', error))
  }

  handleCityChange1 = (address) => {
    this.setState({ 
        location1: address
     })
  }

  searchBuyProperties = async (e) => {
    let comp = this;
    let data = this.state

   if(data.propertyType1 == "") {
     alert("Select Property Type")
     document.getElementById("propertyType1").focus();
     return
   }

    firebase.firestore().collection("Property")
    .where("ApprovalStatus", "==", "Approved")
    .where("PropertyFor", "==", "Sell")
    .where("PropertyType", "==", data.propertyType1)
    .onSnapshot(function(querySnapshot) {
        var properties = []
        querySnapshot.forEach(function(doc) {
          properties.push(doc.data())
      })
       comp.setState({
        buyData: properties
      })

      setTimeout(function() {
          comp.setState({
            moveToSearchPage1: true
          })
      }, 1000)
    })
  }

  searchRentProperties = async (e) => {
    let comp = this;
    let data = this.state

   if(data.propertyType2 == "") {
     alert("Select Property Type")
     document.getElementById("propertyType2").focus();
     return
   }

    firebase.firestore().collection("Property")
    .where("ApprovalStatus", "==", "Approved")
    .where("PropertyFor", "==", "Rent")
    .where("PropertyType", "==", data.propertyType2)
    .onSnapshot(function(querySnapshot) {
        var properties = []
        querySnapshot.forEach(function(doc) {
          properties.push(doc.data())
      })
       comp.setState({
        rentData: properties
      })

      setTimeout(function() {
          comp.setState({
            moveToSearchPage2: true
          })
      }, 1000)
    })
  }

  searchLeaseProperties = async (e) => {
    let comp = this;
    let data = this.state

   if(data.propertyType3 == "") {
     alert("Select Property Type")
     document.getElementById("propertyType3").focus();
     return
   }

    firebase.firestore().collection("Property")
    .where("ApprovalStatus", "==", "Approved")
    .where("PropertyFor", "==", "Lease")
    .where("PropertyType", "==", data.propertyType3)
    .onSnapshot(function(querySnapshot) {
        var properties = []
        querySnapshot.forEach(function(doc) {
          properties.push(doc.data())
      })
       comp.setState({
        leaseData: properties
      })

      setTimeout(function() {
          comp.setState({
            moveToSearchPage3: true
          })
      }, 1000)
    })
  }
  

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.banner

        const inlineStyle = {
            backgroundImage: 'url(assets/img/bg2.jpg)'
        }

        const renderInput1 = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
          <div className="autocomplete-root">
              <input className="form-control"  placeholder = "Entry Landmark Location" {...getInputProps()} />
              <div className="autocomplete-dropdown-container">
                  {suggestions.map(suggestion => {
                      const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                      const style = suggestion.active
                      ? { backgroundColor: '#F8F8FF', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                          <div {...getSuggestionItemProps(suggestion, {className, style})}>
                      <span>{suggestion.description}</span>
                      </div>
                      )
                  })}
              </div>
          </div>
      );

    return <div className="banner-area jarallax" style={inlineStyle}>
      {this.state.moveToSearchPage1 ? <Redirect to = {{
        propertyData: this.state.buyData,
        location: this.state.location1,
        propertyType: this.state.propertyType1,
        propertyFor: 'Sell',
        pathname: "/search-list/", 
      }} /> : null}

    {this.state.moveToSearchPage2 ? <Redirect to = {{
            propertyData: this.state.rentData,
            location: this.state.location2,
            propertyType: this.state.propertyType2,
            propertyFor: 'Rent',
            pathname: "/search-list/", 
    }} /> : null}

    {this.state.moveToSearchPage3 ? <Redirect to = {{
            propertyData: this.state.leaseData,
            location: this.state.location3,
            propertyType: this.state.propertyType3,
            propertyFor: 'Lease',
            pathname: "/search-list/", 
    }} /> : null}

          <div className="container">
            <div className="banner-inner-wrap">
              <div className="row">
                <div className="col-12">
                  <div className="banner-inner">
                    <h5 className="sub-title">{ data.subtitle }</h5>
                    <h1 className="title" style = {{color: "black", fontWeight: 800}}>{ data.title1 } <br /> { data.title2 }</h1>
                  </div>
                </div>
                <div className="col-12">
                  <div className="banner-search-wrap">
                    <ul className="nav nav-tabs rld-banner-tab">
                      <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#tabs_1">For Buy</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tabs_2">For Rent</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#tabs_3">For Lease</a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="tabs_1">
                        <div className="rld-main-search">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                              <div className="rld-single-input left-icon">
                              {/* <PlacesAutocomplete
                                value={this.state.location1}
                                onChange={this.handleCityChange1}
                                onSelect={this.handleSelect1}
                                id = "location1"
                                placeholder = "Entry Landmark Location"
                              >
                                {renderInput1}
                              </PlacesAutocomplete>  */}
                               <input type="text" id = "location1" name = "location1" 
                                onChange={this.handleChnage} placeholder="Entry Landmark Location" />
                              </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 readeal-top">
                              <div className="rld-single-select">
                                  <select className="select single-select" id = "propertyType1" name = "propertyType1" onChange={this.handleChnage}>
                                    <option value= "">Property Type</option>
                                    <option value= "Flat">Flat</option>
                                    <option value= "House">House/Villa</option>
                                    <option value= "Plot">Plot/Site</option>
                                    <option value= "Shop">Shop/Showroom</option>
                                    <option value= "Office Spaces">Office Spaces</option>
                                    <option value= "Commercial Land">Commercial Land</option>
                                    <option value= "Agricultural Land">Agricultural Land</option>
                                  </select>
                              </div>
                            </div>
                            {/* <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Room</option>
                                  <option value={2}>Room 1</option>
                                  <option value={3}>Room 2</option>
                                  <option value={3}>Room 3</option>
                                </select>
                              </div>
                            </div> */}
                            {/* <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Any Price</option>
                                  <option value={2}>Price 1</option>
                                  <option value={3}>Price 2</option>
                                  <option value={3}>Price 3</option>
                                </select>
                              </div>
                            </div> */}
                            <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                              <button onClick = {this.searchBuyProperties}className="btn btn-yellow" to="/search-list">SEARCH NOW</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tabs_2">
                        <div className="rld-main-search">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                              <div className="rld-single-input left-icon">
                              <input type="text" id = "location2" name = "location2" 
                                onChange={this.handleChnage} placeholder="Entry Landmark Location" />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                              <div className="rld-single-select">
                                <select className="select single-select"  id = "propertyType2" name = "propertyType2" onChange={this.handleChnage}>
                                    <option value= "">Property Type</option>
                                    <option value= "Flat">Flat</option>
                                    <option value= "House">House/Villa</option>
                                    <option value= "Plot">Plot/Site</option>
                                    <option value= "Shop">Shop/Showroom</option>
                                    <option value= "Office Spaces">Office Spaces</option>
                                    <option value= "Commercial Land">Commercial Land</option>
                                    <option value= "Agricultural Land">Agricultural Land</option>
                                </select>
                              </div>
                            </div>
                            {/* <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Room</option>
                                  <option value={2}>Room 1</option>
                                  <option value={3}>Room 2</option>
                                  <option value={3}>Room 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Any Price</option>
                                  <option value={2}>Price 1</option>
                                  <option value={3}>Price 2</option>
                                  <option value={3}>Price 3</option>
                                </select>
                              </div>
                            </div> */}
                            <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                              <button onClick = {this.searchRentProperties}className="btn btn-yellow">SEARCH NOW</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tabs_3">
                        <div className="rld-main-search">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                              <div className="rld-single-input left-icon">
                              <input type="text" id = "location3" name = "location3" 
                                onChange={this.handleChnage} placeholder="Entry Landmark Location"  />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="rld-single-select">
                                <select className="select single-select"  id = "propertyType3" name = "propertyType3" onChange={this.handleChnage}>
                                    <option value= "">Property Type</option>
                                    <option value= "Flat">Flat</option>
                                    <option value= "House">House/Villa</option>
                                    <option value= "Plot">Plot/Site</option>
                                    <option value= "Shop">Shop/Showroom</option>
                                    <option value= "Office Spaces">Office Spaces</option>
                                    <option value= "Commercial Land">Commercial Land</option>
                                    <option value= "Agricultural Land">Agricultural Land</option>
                                </select>
                              </div>
                            </div>
                            {/* <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Room</option>
                                  <option value={2}>Room 1</option>
                                  <option value={3}>Room 2</option>
                                  <option value={3}>Room 3</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4">
                              <div className="rld-single-select">
                                <select className="select single-select">
                                  <option value={1}>Any Price</option>
                                  <option value={2}>Price 1</option>
                                  <option value={3}>Price 2</option>
                                  <option value={3}>Price 3</option>
                                </select>
                              </div>
                            </div> */}
                            <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                              <button onClick = {this.searchLeaseProperties}className="btn btn-yellow">SEARCH NOW</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        }
}

export default Banner