import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import newsdetails from '../../data/single-blogdata.json';
import Comments from './comments';
class NewsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
        firebaseData: [],
    }
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    let data = this.props.blogData

    console.log("Blog Data", this.props.blogData)

    return (
      <div>
         <div className="news-details-area">
            <div className="container">
              <div className="news-details-author-social">
                <div className="row">
                  <div className="col-sm-6 mb-4 mg-sm-0">
                    <div className="author">
                      {/* <img src={ publicUrl+"/assets/img/news/15.png" } alt="news" /> */}
                      <p>By { data.Author }</p>
                      <p>{ data.Date }</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    {/* <ul className="social-icon style-two text-sm-right">
                    { data.social.map( ( item, i )=>
                      <li key={ i }>
                        <a className={ item.class } href={ item.url } target="_blank"><i className={ item.icon } /></a>
                      </li>
                     ) }
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="row justify-content-center pd-top-70">
                <div className="col-lg-8">
                  <div className="news-details-wrap">
                    <img className="news-details-thumb" src={data.Image1} alt="img" />
                    <h2 className="title1">{ data.Heading }</h2>
                    <h5 className="title2">{data.SubHeading}</h5>
                    <div className="row news-details-thumb">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <img src={data.Image2} alt={ imagealt } />
                      </div>
                      <div className="col-sm-6">
                        <img src={data.Image3} alt={ imagealt } />
                      </div>
                    </div>
                    <p dangerouslySetInnerHTML={{__html: data.Description.replace(/<li>/gm, "• ").replace(/&nbsp;/gm, '')}}></p>
                  </div>
                  {/* <Comments /> */}
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default NewsDetails;
